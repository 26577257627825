<template>
  <section>
    <PageMetadata />
    <section class="mt-4">
      <div class="container px-0">
        <flash-alert class="my-2" v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level"> 
          <span v-html="flashAlert.msg"></span>
        </flash-alert>
        <loading-overlay
          :active="isLoading"
          :is-full-page="true"
          loader="bars"
        />
        <div class="mb-5">
          <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
          <div
            class="content_below_heading"
            v-if="!!getMetaDetails.description"
            v-html="getMetaDetails.description"
          ></div>
        </div>
      </div>
    </section>
    <section class="mt-4">
      <div class="row" v-if="isLoggedIn">
        <div class="container">
          <div class="row justify-content-center" v-if="shoppingListsCount > 0">
            <div class="col-12 col-sm-6 col-md-4 mb-5 no-letter-spacing" v-for="(shoppingList, index) in shoppingLists" :key="index">
              <a v-if="shoppingList.is_hol_holiday" :href="`/${shoppingList.slug}`">
                <div class="shopping-list-image" :style="{ backgroundImage: `url(${shoppingList.image})` }">
                  <div class="main-banner-overlay d-flex align-items-center justify-content-center text-center">
                    <div class="container text-white">
                      <span class="shopping-list-name">{{ shoppingList.name }}</span>
                    </div>
                  </div>
                </div>
              </a>
              <a v-else :href="`/special-offers/${shoppingList.slug}`">
                <div class="shopping-list-image" :style="{ backgroundImage: `url(${shoppingList.image})` }">
                  <div class="main-banner-overlay d-flex align-items-center justify-content-center text-center">
                    <div class="container text-white">
                      <span class="shopping-list-name">{{ shoppingList.name }}</span>
                    </div>
                  </div>
                </div>
              </a>
              <div class="shopping-list-info text-center">
                Delivery Dates: {{ shoppingList.delivery_dates_formatted }}
                <br>
                <span class="font-weight-bold">Ordering Cut Off {{ shoppingList.cutoff_date_formatted }}</span>
              </div>
              <div class="text-center mt-4">
                <template v-if="shoppingList.display_pdf">
                  <a v-if="shoppingList.is_hol_holiday" class="btn btn-theme width-100" style="margin-right: 33px !important;" :href="`/${shoppingList.slug}`">SHOP</a>
                  <a v-else class="btn btn-theme width-100" style="margin-right: 33px !important;" :href="`/special-offers/${shoppingList.slug}`">SHOP</a>
                  <a class="btn btn-theme width-100" style="margin-left: 33px !important;" @click="printDocument(shoppingList.slug)">PDF</a>
                </template>
                <template v-else>
                  <a v-if="shoppingList.is_hol_holiday" class="btn btn-theme width-100" :href="`/${shoppingList.slug}`">SHOP</a>
                  <a v-else class="btn btn-theme width-100" :href="`/special-offers/${shoppingList.slug}`">SHOP</a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container px-0" v-else>
        <p class="regular-p">Kindly <a href="/login">log-in</a> to see the available products for your branch. If you are currently not a Mayesh Wholesale customer, complete the <a href="/register">registration form </a>to our list of specials.</p>
      </div>
    </section>
    <div
      id="page_content_below"
      class="mt-10 content-wrapper"
      v-if="!!getMetaDetails.description_below_dynamic"
      v-html="getMetaDetails.description_below_dynamic"
    ></div>
  </section>
</template>
<script>
import { FlashAlert, PageMetadata } from "@/components";
import { misc } from "@/mixins";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ShoppingLists",
  mixins: [misc],
  components: {
    FlashAlert,
    PageMetadata
  },
  data() {
    return {
      pdf_content: '',
      shoppingLists: [],
      shoppingListFilename: 'special-offer',
      loader: "bars",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["isSalesRep", "isESales"]),
    shoppingListsCount() {
      return this.shoppingLists.length;
    },
  },
  mounted() {
    localStorage.setItem("currentPage", "special-offers");
    this.fetchMetaDetails();
    this.fetchShoppingLists();
  },
  methods: {
    printDocument(slug) {
      if (!this.isLoggedIn) {
        return false;
      }
      this.isLoading = true;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getShoppingListPdf",
            {
              slug: slug,
              is_sales_rep: this.isSalesRep,
              is_e_sales: this.isESales,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              responseType: "arraybuffer",
              dataType: "blob",
            }
          )
          .then((responseBlob) => {
            if (responseBlob.headers['content-type'] === 'application/json'){
              let response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(responseBlob.data)));
              if (response.error) {
                let message = response.message;
                let payload = { message: message, level: 'error' };
                this.emitter.emit("displayFlash", payload);
              }
            } else {
              const file = new Blob([responseBlob.data], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(file);
              let fileName = slug;
              const link = document.createElement("a");
              link.href = fileURL;
              link.download = `${fileName}.pdf`;
              link.click();
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            console.log("Something went wrong");
          });
      } catch {
        this.isLoading = false;
        console.log("Something went wrong");
      }
    },
    fetchShoppingLists() {
      if (!this.isLoggedIn) {
        return false;
      }
      this.isLoading = true;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getShoppingLists",
            {},
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.shoppingLists = response.data.shopping_lists;
            if (this.shoppingLists.length < 1) {
              const errMsg = "Currently, we don't have any shopping lists. To ensure you get the freshest product, please check back later to place you order.";
              this.triggerFlashAlert(errMsg, "error");
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            console.log("Something went wrong");
          });
      } catch {
        this.isLoading = false;
        console.log("Something went wrong");
      }
    },
    showLoader() {
      this.isLoading = true;
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: 700 !important;
}
a {
  text-decoration: none;
}
</style>