<template>
  <section>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <h1 class="mb-4 mb-md-5 px-0 pb-0">{{ getMetaDetails.page_h1 }}</h1>
    <div
      class="content_below_heading"
      v-if="!!getMetaDetails.description"
      v-html="getMetaDetails.description"
    ></div>
    <div class="row login-sections">
      <div class="col-md-5 mb-4 mb-md-3 login-section-left">
        <p class="mt-0 mb-4 mb-md-5 h6 text-transform-reset letter-spacing-reset">
          Welcome Back.<br/>
          Log in to your account:
        </p>
        <!-- <p class="mb-3">
          <strong class="m-0">I am a returning customer</strong>
        </p> -->
        <form @submit.prevent="doLogin">
          <div class="form-floating">
            <input
              type="text"
              name="email"
              placeholder="E-Mail Address"
              v-model.trim="form.email"
              id="input-email"
              class="form-control"
            />
            <label class="control-label" for="input-email"
              >E-Mail Address</label
            >
            <span class="text-danger" v-if="form.hasError('email')">
              {{ form.getErrors("email") }}
            </span>
          </div>
          <div class="form-floating">
            <input
              type="password"
              name="password"
              placeholder="Password"
              v-model="form.password"
              id="input-password"
              class="form-control"
            />
            <label class="control-label" for="input-password">Password</label>
            <span class="text-danger" v-if="form.hasError('password')">
              {{ form.getErrors("password") }}
            </span>
          </div>
          <button type="submit" value="Login" class="btn btn-primary mt-4">
            Login
          </button>
          <div class="row mt-4" style="margin-top: 8px">
            <div class="col-12">
              <a href="/forgot-password">Reset Password</a>
            </div>
            <div class="col-12 mt-2">
              <a href="/trouble-logging-in">Having trouble logging in?</a>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-7 mb-3 login-section-right mt-5 mt-md-0">
        <p class="mt-0 mb-4 mb-md-5 h6 text-transform-reset letter-spacing-reset">
          Don't have an account yet?<br/>
          Register now for online access:
        </p>
        <ul class="mb-4 mb-md-5 check-list regular-p">
          <li>
            <i class="fa-regular fa-circle-check"></i>Shop for hundreds of flowers online.
          </li>
          <li>
            Access past invoices upto 5 years old
          </li>
          <li>
            View your branch's Price Lists online
          </li>
          <li>
            Request quotes using our extensive flower library
          </li>
          <li>
            Utilize our Product Planner for upcoming events 
          </li>
          <li>
            and so much more! 
          </li>
        </ul>
        <div class="mt-md-auto">
          <a href="register" class="btn btn-primary">Register Now</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FlashAlert, PageMetadata } from "@/components";
import { mapMutations, mapActions } from "vuex";
import axios from "axios";
import { Validator } from "@/classes";
import { misc } from "@/mixins";

export default {
  name: "LoginPage",
  mixins: [misc],
  components: {
    FlashAlert,
    PageMetadata,
  },
  data() {
    return {
      previousRoute: {
        name: "shop",
      },
      form: new Validator({
        email: {
          rules: ["required", "email"],
        },
        password: {
          rules: ["required"],
        },
      }),
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path != "" && from.path != null) {
        vm.previousPath = from.path;
      } else {
        vm.previousPath = "/";
      }
    });
  },
  mounted() {
    localStorage.setItem("currentPage", "login");
    this.fetchProductsTypes();

    this.emitter.on("unauth", (payload) => {
      this.showFlashAlert(payload.msg);
    });
    this.fetchMetaDetails();
  },
  unmounted() {
    this.emitter.off("unauth");
  },
  methods: {
    ...mapMutations(["storeUserDetails"]),
    ...mapActions(["fetchWishList", "addToQuoteBulk"]),

    fetchProductsTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getProductTypes",
          {}, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.productTypes = response.data.types;
          localStorage.setItem(
            "productTypes",
            JSON.stringify(response.data.types)
          );
        });
    },

    doLogin() {
      this.form
        .validate()
        .then(() => {
          this.emitter.emit("showLoader");
          return axios.post(
            process.env.VUE_APP_API_BASE + "auth/login",
            {
              email: this.form.email,
              password: this.form.password,
            }, // the data to post
            {
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
        })
        .then((response) => {
          if (response.data.error == true) {
            this.showFlashAlert(response.data.message);
          } else {
            let customer = response.data.data.customer;

            localStorage.setItem("token", response.data.data.token);

            this.storeUserDetails({
              name: customer.first_name + " " + customer.last_name,
              fname: customer.first_name,
              email: customer.email,
              branch_id: customer.komet_branch_id,
              is_sales_rep: 0,
              is_e_sales: 0,
              can_enroll_loyalty: customer.can_enroll_loyalty,
              is_enrolled_loyalty: customer.is_enrolled_loyalty,
              loyalty_status: customer.loyalty_status,
            });
            this.$gtag.event("login", {
              event_category: "engagement",
              event_label: "method",
              event_value: 1,
            });

            this.emitter.emit("updateMegamenu")
            this.addToQuoteBulk()
            if (this.isLoggedIn) {
              this.fetchWishList()
            }
            window.location.href = this.previousPath
          }
        })
        .catch((response) => {
          if (!("validation" in response)) {
            if (response.data) {
              this.showFlashAlert(response.data.message);
            }
          }
        })
        .finally(() => this.emitter.emit("hideLoader"));
    },
  },
};
</script>
<style>
  .check-list{
    padding-left:0;list-style:none;
  }
  .check-list li:before{
    position:absolute;
    content:'';
    height:1.5em;
    width:1.5em;
    left:0;top:0;
    background: url('../assets/image/circle-tick_icon.png') no-repeat;
    background-size:1.5em;
  }
  .check-list li{
    padding-left:calc(1.5em + 10px);
    position: relative;
  }
  .check-list li + li{
    margin-top:10px;
  }
  .login-sections p.h6{
    line-height:1.6;
  }
  @media screen and (min-width:768px) {
    .login-sections .login-section-left{
      padding-right:50px;
    }
    .login-sections .login-section-right{
      padding-left:50px;
      border-left:2px solid #9f9f9f;
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-width:992px) {
    .login-sections .login-section-left{
      padding-right:70px;
    }
    .login-sections .login-section-right{
      padding-left:70px;
    }
  }
</style>