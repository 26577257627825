<template>
  <section>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <flash-alert v-if="dates != null && isLoggedIn && dateExists != null" :show="!dateExists">
      <span>Please checkout to view this product's inventory!</span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="container">
      <!-- Product Detail -->
      <div class="product-detail mt-10">
        <div class="row product-details-row">
          <!-- Product Image -->
          <div class="col-lg-5 col-md-5 col-sm-12 mb-30 product-img-box px-0" style="max-width: 500px">
            <div>
              <!-- <button @click="showSingle">Show single picture.</button>
                      <button @click="showMultiple">Show a group of pictures.</button> -->

              <!-- all props & events -->
              <vue-easy-lightbox scrollDisabled escDisabled moveDisabled :visible="visible" :imgs="imgs" :index="index"
                @hide="handleHide"></vue-easy-lightbox>
            </div>
            <div class="product-image">
              <!--Tab Content Start-->
              <!-- <div class="product-details-large" @click="showMultiple" id="myTabContent-3"> -->
              <div class="product-details-large" id="myTabContent-3">
                <div class="tab-pane fade show active" id="single-slide-one">
                  <!--Single Product Image Start-->
                  <!-- <div class="single-product-img img-full mb-3 mb-md-0"> -->
                  <div class="img-full mb-3 mb-md-0">
                    <img v-if="imagesCount >= 1" :src="currentImage" class="" :alt="name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(productDetails.product_type_id)
                      " class="" :alt="name" />
                    <!-- <span class="magnifier">
                      <i class="fa-solid fa-magnifying-glass-plus"></i>
                    </span> -->
                  </div>
                  <!--Single Product Image End-->
                  <!-- <p class="text-center w-100 the-disclaimer" v-if="currentDisclaimer">
                    {{ currentDisclaimer }}
                  </p> -->
                </div>
              </div>
              <!--Tab Content End-->
              <!--Tab Menu Start-->
              <div class="single-product-menu" v-if="imagesCount > 1">
                <div class="nav single-slide-menu slick-initialized slick-slider" role="tablist">
                  <div class="slick-list draggable">
                    <div class="slick-track" style="
                        opacity: 1;
                        width: 450px;
                        transform: translate3d(0px, 0px, 0px);
                      ">
                      <div class="
                          single-tab-menu
                          slick-slide slick-current slick-active
                        " :class="[
                          'thumbnail-image',
                          activeImage == thumbIndex ? 'activeThumbnail' : '',
                        ]" v-for="(thumbnailImg, thumbIndex) in imgs" :key="thumbIndex" data-slick-index="0"
                        aria-hidden="false" tabindex="0" style="width: 150px">
                        <a class="" data-toggle="tab" @click="activateImage(thumbIndex)" tabindex="0"><img
                            :src="thumbnailImg.src" alt="" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Product Image -->
          <!-- Product Content -->
          <div class="col-lg-7 product-modified right col-md-7 col-sm-12 mb-30">
            <div class="product-detail-content">
              <span class="d-inline-block pt-5 mb-1 pb-2">
                <h1 class="product-title sub-header m-0 p-0 pb-1">{{ name }}</h1>
              </span>
              <div class="d-flex justify-content-between">
                <div class="product-rating">
                  <template v-if="isLoggedIn && reviews_count > 0 && productDetails.allow_reviews" >
                    <ul v-if="!!rating" class="star-rating">
                      <li v-for="index in 5" :key="index" :class="{ 'star-color': index <= rating }">
                        <i v-if="index <= rating" class="fa-solid fa-star" aria-hidden="true"></i>
                        <i v-else class="fa-regular fa-star" aria-hidden="true"></i>
                      </li>
                    </ul>
                    <ul v-else class="star-rating">
                      <li v-for="index in 5" :key="index" :class="{ 'star-color': index <= rating }">
                        <i class="fa-regular fa-star" aria-hidden="true"></i>
                      </li>
                    </ul>
                    <span class="mx-1" v-if="!!actual_rating">{{ actual_rating }}</span>
                    <a @click.prevent="displayReview" class="control-tabs reviews-link">reviews</a>
                  </template>
                </div>
                <div class="signle-product-button-wrapper">
                  <add-to-wishlist v-if="showAddToWishlist" :prod="productDetails" :showText="true" class="position-static"></add-to-wishlist>
                </div>
              </div>
              <p class="product-short-description">
                <span v-if="color">Color: </span>{{ color }}<br />
                <template v-if="productDetails.short_description">
                  <span>Description: </span>
                  <span class="w-auto d-inline" v-html="productDetails.short_description"></span><br />
                </template>
                <template v-if="productDetails.latin_name">
                  <span>Botanical: </span>
                  <span class="w-auto d-inline" v-html="productDetails.latin_name"></span><br />
                </template>
                <template v-if="productDetails.vbn"><span>VBN: </span>{{ productDetails.vbn }}<br /></template>
                <!-- <template v-if="breeder"><span>Breeder: </span>{{ breeder }}</template> -->
                <template v-if="substituteProducts.length > 0">
                  <span  class="w-100 fw-bold pb-2 mt-4">
                    Suggested Substitutions: 
                    <Popper id="suggested-substitutes" class="no-border no-margin" :arrow="true" :hover="true" 
                      content="Suggested Substitutes are merely an indication of what could be used in case a particular variety is not available, but please know that your personal preferred substitute might vary based on many other variables such as color palette, flower type, product performance, etc." 
                      style="max-width: 14px !important; margin-left: 1px !important;">
                      <span>
                        <i class="fa-solid fa-circle-info"></i>
                      </span>
                    </Popper>
                  </span>
                  <template v-for="(substituteProduct, index) in substituteProducts" :key="index">
                    <a :href="`/${substituteProduct.seo_url}`" class="fw-bold d-block line-height-16 pb-1">{{ substituteProduct.name }}</a>
                  </template>
                </template>
              </p>
              <div v-if="dates != null && isLoggedIn && dateExists" class="delivery-address d-flex align-items-center justify-content-between my-4 pt-3 border-top">
                <label class="col-sm-4 mr-5 mb-0 p-0" for=""><b>Delivery Date:</b></label>
                <delivery-date-picker v-model:dateProp="tmpDate" @deliveryDateChanged="processDateChange"
                  :minDate="minDate" :maxDate="maxDate" :disabledDates="disabledDates" :pageKey="deliveryDateType" />
              </div>
              <!-- START -->
              <div v-if="dates != null && isLoggedIn && productOptions.length > 0 && dateExists">
                <h5 class="text-center text-md-start"><b>Choose Your Option</b></h5>
                <div class="product-hover">
                  <div v-for="(productOption, index) in productOptions" @click="updateProduct(productOption, index)" 
                    :key="index" class="position-relative d-flex div-product border border-1" :class="{ 'div-highlight': selectedIndex == index }">
                    <div class="d-flex div-product-breakdown d-md-inline"  :class="{ 'd-none': productOption.highlight_name == null || productOption.highlight_name == '' }">
                      <Popper v-if="productOption.highlight_name" :arrow="true" :hover="true"
                        :content="productOption.highlight_name">
                        <div class="ribbon2">
                          <span
                            :style="`border-color: ${productOption.highlight_hex_code}; background: ${productOption.highlight_hex_code}; background: linear-gradient(${ productOption.highlight_hex_code } 0%, ${ productOption.highlight_hex_code } 100%);`">{{
                            productOption.highlight_name }}</span>
                        </div>
                      </Popper>
                      <Popper class="breakdown-popper" v-if="
                          productOption.breakdown != null &&
                          productOption.breakdown.length > 2
                        " :arrow="true" :hover="true">
                        <template #content>
                          <div class="breakdown-popper tool-tip" v-if="productOption.programtype == 'lal' || productOption.programtype == 'prb' || productOption.programtype == 'wsp'" 
                            v-html="getBreakdownForPulls(productOption.breakdown, productOption.box_unit_singular)"></div>
                          <div v-else v-html="getBreakdown(productOption.breakdown, productOption.box_unit_singular)"></div>
                        </template>
                        <span class="star-color"><i class="fa-solid fa-star text-warning" aria-hidden="true"></i></span>
                      </Popper>
                      <Popper v-if="productOption.highlight_description" class="no-border no-margin" :style="`margin-left: 10px !important;`" :arrow="true" :hover="true" :content="productOption.highlight_description" style="margin:0;">
                        <span class="us_img_icon">
                          <i class="fa-solid fa-circle-info"></i>
                        </span>
                      </Popper>
                      <!-- <Popper class="no-border no-margin" :arrow="true" v-if="productOption.country_id > 0"
                        :hover="true" :content="productOption.country_name">
                        <span class="us_img_icon">
                          <img :src="`${imagePath}` + productOption.country_flag" /></span>
                      </Popper> -->
                    </div>
                    <div class="div-product-farm farm-name text-center align-self-center m-auto m-md-0 d-md-inline" :class="{ 'd-none': productOption.farm_name == null || productOption.farm_name == '' }">{{ productOption.farm_name }}</div>
                    <div class="div-product-packing text-center align-self-center m-auto m-md-0">
                      <template v-if="productOption.programtype == 'mdb' || productOption.programtype == 'lal' || productOption.programtype == 'hgs' || productOption.programtype == 'fdb'">{{ productOption.qty }}&nbsp;</template>
                      <template v-if="productOption.programtype == 'hgs'">
                        <template v-if="productOption.box_name_singular != 'each'">
                          {{ productOption.qty > 1 ? productOption.box_name_plural : productOption.box_name_singular }}
                          {{ productOption.unit_count > 1 ? " of " + productOption.unit_count : "" }}
                        </template>
                        | ${{ $filters.numberFormat(productOption.price_per_unit) }}/{{ productOption.box_name_singular }} |
                      </template>
                      <template v-else>
                        {{ productOption.qty > 1 ? productOption.box_name_plural : productOption.box_name_singular }}
                        <span v-if="productOption.unit_count > 1 && productOption.box_name_singular != productOption.box_unit_singular">
                          ({{ productOption.unit_count }} {{ productOption.box_unit_plural }})
                        </span>
                        | ${{ $filters.numberFormat(productOption.price_per_stem) }}/{{ productOption.box_unit_plural }} |
                      </template>
                    </div>
                    <div class="div-product-price text-center align-self-center m-auto m-md-0">
                      <b>[${{ $filters.numberFormat(productOption.price_per_unit) }}]</b>
                      <span v-if="productOption.special" class="ms-2"><span class="badge bg-peach">{{ productOption.special }}% Off</span></span>
                    </div>
                  </div>
                </div>
                <!-- END -->
              </div>
              <div v-if="dates != null && isLoggedIn && productOptions.length > 0 && dateExists"
                class="d-flex align-items-center">
                <div class="product-price">
                  <span><span class="product-price-text choose-option-label" id="product-price">${{
                      $filters.numberFormat(currentProduct.price_per_unit)
                      }}</span></span>
                </div>
                <div class="signle-product-qty-wrapper align-items-center d-flex">
                  <add-to-cart :max="currentProduct.qty" :prod="currentProduct" :min="currentProduct.min_qty"
                    :deliveryDate="date" :btnTypeIcon="false" />
                </div>
              </div>
              <div class="align-items-right just-right mb-2 d-flex" v-if="!isLoggedIn">
                <a :href="'/login'" class="btn btn-theme ms-3 login-quote-btn">LOGIN</a>
              </div>
              <div class="signle-product-unit-wrapper align-items-center my-4 border-top pt-3" v-if="(productDetails.status && fll_info) || !isLoggedIn">
                <div class="row align-items-center" v-if="productDetails.status && fll_info">
                  <div class="col-md product-qty text-md-start text-center">
                    <label class="w-auto p-0 mb-2 mb-md-0" for="">
                      <input type="number" 
                        :min="quantity_increment"
                        :max="maxQuoteQuantity"
                        :step="quantity_increment" class="form-control" v-model="quote_quantity"
                        style="width: 76px; margin-right: 5px" /></label>
                    <span v-if="productDetails.category.unit">
                      {{ productDetails.category.unit.unit }}</span>
                    <select class="form-select d-inline-block w-auto bg-transparent"
                      @change="setUnitType($event, product)" v-else>
                      <option v-for="(fllUnitType, index) in fllUnitTypes" :key="index" :value="fllUnitType.id"
                        :selected="fllUnitType.id == fllUnitTypes[0].id">
                        {{ fllUnitType.unit }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-auto w-113 text-center text-md-left">
                    <add-to-quote :quant="quote_quantity" :prods="[
                        {
                          product_id: productDetails.id,
                          unit_id_dd: productDetails.unit_id_dd,
                          product_id: productDetails.id,
                          image: productDetails.images[0],
                          name: productDetails.name,
                          seo_url: productDetails.seo_url,
                          increment_quantity:productDetails.increment_quantity,
                          product_type_id: productDetails.product_type_id,
                          unit: productDetails.category.unit
                            ? productDetails.category.unit.unit
                            : '',
                          unit_id: productDetails.category.unit
                            ? productDetails.category.unit.id
                            : 0,
                        },
                      ]" :comingSoon="productDetails.coming_soon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Product Content -->
        </div>
        <!--  Product Details end-->
      </div>
    </div>
    <div v-if="productDetails.description" class="container">
      <span v-html="productDetails.description"></span>
    </div>
    <section v-if="substituteProducts.length > 0" class="d-none">
      <div class="container">
        <div class="recently-viewed my-5">
          <h5 class="mb-4 font-weight-bold">Substitute with</h5>
          <div class="row">
            <div class="col-lg-3 mb-4" v-for="(substituteProduct, index) in substituteProducts" :key="index">
              <div v-if="substituteProduct.status == 1" class="product-wrapper text-center">
                <div class="product-img px-4 py-3 position-relative">
                  <add-to-wishlist :prod="substituteProduct"></add-to-wishlist>
                  <a :href="`/${substituteProduct.seo_url}`">
                    <img v-if="substituteProduct.primary_image != null" :src="`${imagePath}` + substituteProduct.primary_image.image" class="m-0"
                      :alt="substituteProduct.name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(substituteProduct.product_type_id)
                      " class="m-0" :alt="substituteProduct.name" />
                  </a>
                </div>
                <div class="px-3 pb-3">
                  <div class="product-details">
                    <div class="product-name mb-1">
                      <a :href="`/${substituteProduct.seo_url}`">{{
                        substituteProduct.name
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div ref="review-container" class="ccontainer mt-0 mt-md-4" v-if="allTabs.length">
      <tabs-js :tabs="allTabs">
        <template v-slot:availability v-if="fll_info">
          <div class="row">
            <div id="avalability" v-if="fll_info != null" class="container tab-pane active show">
              <div class="container">
                <div class="recently-viewed my-5">
                  <div class="row text-center w-100 m-auto" style="min-height: 45px">
                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Jan
                      <br />
                      <Popper v-if="fll_info.january_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.january_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Feb
                      <br />
                      <Popper v-if="fll_info.february_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.february_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Mar
                      <br />
                      <Popper v-if="fll_info.march_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.march_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Apr
                      <br />
                      <Popper v-if="fll_info.april_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.april_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      May
                      <br />
                      <Popper v-if="fll_info.may_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.may_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Jun
                      <br />
                      <Popper v-if="fll_info.june_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.june_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Jul
                      <br />
                      <Popper v-if="fll_info.july_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.july_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Aug
                      <br />
                      <Popper v-if="fll_info.august_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.august_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Sep
                      <br />
                      <Popper v-if="fll_info.september_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.september_status == 1" :arrow="true" :hover="true"
                        content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Oct
                      <br />
                      <Popper v-if="fll_info.october_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.october_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Nov
                      <br />
                      <Popper v-if="fll_info.november_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.november_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>

                    <div class="col-md-1 availability-month" data-toggle="tooltip" data-placement="bottom" title=""
                      data-original-title="Not Available">
                      Dec
                      <br />
                      <Popper v-if="fll_info.december_status == 2" :arrow="true" :hover="true" content="Limited">
                        <span class="fa-solid fa-triangle-exclamation text-warning"></span>
                      </Popper>

                      <Popper v-else-if="fll_info.december_status == 1" :arrow="true" :hover="true" content="Available">
                        <span class="fa-solid fa-check text-green"></span>
                      </Popper>

                      <Popper v-else :arrow="true" :hover="true" content="Not Available">
                        <span class="fa-solid fa-xmark text-danger"></span>
                      </Popper>
                    </div>
                  </div>
                  <span style="font-size: 10px">Use the availability calendar as a guide because availability may differ due to weather, country of origin, grower, and other factors.</span>
                </div>
              </div>
            </div>
            <div v-else>Currently, No availability</div>
          </div>
        </template>
        <template v-slot:reviews v-if="isLoggedIn && (reviews_count > 0 || productDetails.allow_reviews)">
          <div class="row">
            <div class="container pt-5">
              <div class="row review-wrap">
                <div class="col-md-6" v-if="reviews_count > 0" :class="(productDetails.allow_reviews) ? 'col-md-6' : 'col-md-12'">
                  <div id="review">
                    <div class="review-container">
                      <div class="row">
                        <pagination-row :recordsCount="reviews_count" :perPage="perPage" :currPage="pageNumb"
                          @pageChange="changePage" />
                      </div>
                      <div class="row" id="review-list" v-for="(review, index) in reviews" :key="index">
                        <div class="col-md-9">
                          <strong class="mt-0">{{ review.first_name }}
                            <span class="rating-text">
                              <ul v-if="review.rating" class="star-rating">
                                <li v-for="indexx in 5" :key="indexx">
                                  <i class="fa-solid fa-star" :class="{
                                      fa: indexx <= review.rating,
                                      far: indexx > review.rating,
                                    }" aria-hidden="true"></i>
                                </li>
                              </ul>
                            </span>
                          </strong>
                        </div>
                        <div class="col-md-3">
                          {{ formatUsDate(review.created_at) }}
                        </div>
                        <div class="col-md-12 my-4" v-html="review.review"></div>
                        <hr class="my-4" />
                      </div>
                      <div class="row">
                        <pagination-row :recordsCount="reviews_count" :perPage="perPage" :currPage="pageNumb"
                          @pageChange="changePage" />
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="(reviews_count > 0) ? 'col-md-6' : 'col-md-12'">
                  <div v-if="isLoggedIn && productDetails.allow_reviews" class="review-form pt-0">
                    <form name="form-review" id="form-review">
                      <div class="col-12">
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label for="review-stars">Review Stars</label>
                            <select name="rating" id="review-stars" class="form-select" v-model="reviewRating">
                              <option value="5">5</option>
                              <option value="4">4</option>
                              <option value="3">3</option>
                              <option value="2">2</option>
                              <option value="1">1</option>
                            </select>
                          </div>
                          <div class="form-group col-md-4">
                            <label for="review-author">* Your Name</label>
                            <input type="text" name="name" class="form-control" id="review-author" v-model="user_name"
                              placeholder="Your Name" readonly="" />
                          </div>
                          <div class="form-group col-md-4">
                            <label for="review-email">* Email Address</label>
                            <input type="text" class="form-control" id="review-email" placeholder="Email Address"
                              v-model="email" readonly="" />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="review-text">Your Review</label>
                          <textarea name="text" class="form-control" id="review-text" v-model="reviewText"
                            rows="6"></textarea>
                          <span class="text-danger" v-if="errorReview">
                            {{ errorText }}</span>
                        </div>
                        <div class="form-group text-align-center mt-10">
                          <button id="button-review" type="button" @click="storeReviews"
                            class="btn btn-lg btn-block btn-theme m-auto">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    <p id="message" class="mt-3">Your email address will not be published. Required fields are marked *</p>
                  </div>
                  <div v-else-if="!isLoggedIn">
                    <a href="/login">Login</a> to submit a review.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:videos>
          <div class="videos">
            <div v-for="(productVideo, index) in videos" :key="index">
              <div class="video-responsive" v-html="prepareVideo(productVideo.platform.code, productVideo.video_id)"></div>
            </div>
          </div>
        </template>
      </tabs-js>
    </div>

    <section v-if="crossSellProducts.length > 0">
      <div class="container">
        <div class="recently-viewed my-5">
          <h5 class="mb-4 font-weight-bold">Customers Also Purchased</h5>
          <div class="row">
            <div class="col-lg-3 mb-4" v-for="(crosssellProduct, index) in crossSellProducts" :key="index">
              <div class="product-wrapper text-center">
                <div v-if="crosssellProduct.status == 1" class="product-img px-4 py-3 position-relative">
                  <add-to-wishlist :prod="crosssellProduct"></add-to-wishlist>
                  <a :href="`/${crosssellProduct.seo_url}`">
                    <img v-if="crosssellProduct.primary_image != null" :src="`${imagePath}` + crosssellProduct.primary_image.image" class="m-0"
                      :alt="crosssellProduct.name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(crosssellProduct.product_type_id)
                      " class="m-0" :alt="crosssellProduct.name" />
                  </a>
                </div>
                <div class="px-3 pb-3">
                  <div class="product-details">
                    <div class="product-name mb-1">
                      <a :href="`/${crosssellProduct.seo_url}`">{{
                        crosssellProduct.name
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="relatedProducts.length > 0">
      <div class="container">
        <div class="recently-viewed my-5">
          <h5 class="mb-4 font-weight-bold">Related Products</h5>
          <div class="row">
            <div class="col-lg-3 mb-4" v-for="(relatedProduct, index) in relatedProducts" :key="index">
              <div class="product-wrapper text-center">
                <div v-if="relatedProduct.status == 1" class="product-img px-4 py-3 position-relative">
                  <add-to-wishlist :prod="relatedProduct"></add-to-wishlist>
                  <a :href="`/${relatedProduct.seo_url}`">
                    <img v-if="relatedProduct.primary_image != null" :src="`${imagePath}` + relatedProduct.primary_image.image" class="m-0"
                      :alt="relatedProduct.name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(relatedProduct.product_type_id)
                      " class="m-0" :alt="relatedProduct.name" />
                  </a>
                </div>
                <div class="px-3 pb-3">
                  <div class="product-details">
                    <div class="product-name mb-1">
                      <a :href="`/${relatedProduct.seo_url}`">{{
                        relatedProduct.name
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="upsellProducts.length > 0">
      <div class="container">
        <div class="recently-viewed my-5">
          <h5 class="mb-4 font-weight-bold">Upgrade</h5>
          <div class="row">
            <div class="col-lg-3 mb-4" v-for="(upsellProduct, index) in upsellProducts" :key="index">
              <div v-if="upsellProduct.status == 1" class="product-wrapper text-center">
                <div class="product-img px-4 py-3 position-relative">
                  <add-to-wishlist :prod="upsellProduct"></add-to-wishlist>
                  <a :href="`/${upsellProduct.seo_url}`">
                    <img v-if="upsellProduct.primary_image != null" :src="`${imagePath}` + upsellProduct.primary_image.image" class="m-0"
                      :alt="upsellProduct.name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(upsellProduct.product_type_id)
                      " class="m-0" :alt="upsellProduct.name" />
                  </a>
                </div>
                <div class="px-3 pb-3">
                  <div class="product-details">
                    <div class="product-name mb-1">
                      <a :href="`/${upsellProduct.seo_url}`">{{
                        upsellProduct.name
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="recently_viewed" v-if="recentlyViewed.length > 0">
      <div class="container px-0">
        <div class="recently-viewed my-5">
          <h5 class="mb-4 font-weight-bold">Recently Viewed</h5>
          <div class="row">
            <div class="col-lg-3 mb-4" v-for="(recentlyViewedProduct, index) in recentlyViewed" :key="index">
              <div class="product-wrapper text-center">
                <div class="product-img px-4 py-3 position-relative">
                  <add-to-wishlist :prod="recentlyViewedProduct.product"></add-to-wishlist>
                  <a :href="`/${recentlyViewedProduct.product.seo_url}`">
                    <img v-if="recentlyViewedProduct.product.primary_image" :src="
                        `${imagePath}` +
                        recentlyViewedProduct.product.primary_image.image
                      " class="m-0" :alt="recentlyViewedProduct.product.name" />
                    <img v-else :src="
                        `${imagePath}` +
                        getDefaultImage(
                          recentlyViewedProduct.product.product_type_id
                        )
                      " class="m-0" :alt="recentlyViewedProduct.product.name" />
                  </a>
                </div>
                <div class="px-3 pb-3">
                  <div class="product-details">
                    <div class="product-name mb-1">
                      <a :href="`/${recentlyViewedProduct.product.seo_url}`">{{
                        recentlyViewedProduct.product.name
                        }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import _ from "lodash";
import Popper from "vue3-popper";
import VueEasyLightbox from "vue-easy-lightbox";
import {
  AddToCart,
  TabsJs,
  FlashAlert,
  DeliveryDatePicker,
  AddToWishlist,
  AddToQuote,
  PaginationRow,
  PageMetadata,
} from "@/components";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  name: "ProductDetail",
  mixins: [misc],
  components: {
    AddToCart,
    FlashAlert,
    TabsJs,
    DeliveryDatePicker,
    AddToWishlist,
    AddToQuote,
    Popper,
    PaginationRow,
    VueEasyLightbox,
    PageMetadata,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    prevUrl: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SITE_URL: process.env.VUE_APP_URL_BASE,
      defaultTab: "availability",
      showAddToWishlist: false,
      errorReview: false,
      us_message: "US Product",
      quote_quantity: 1,
      selectedIndex: 0,
      errorText: "",
      productDetails: {},
      productOptions: [],
      videos: [],
      availableDates: [],
      currentProduct: {},
      fllUnitTypes: JSON.parse(localStorage.getItem("fllUnitTypes")),
      substituteProducts: [],
      crossSellProducts: [],
      relatedProducts: [],
      upsellProducts: [],
      recentlyViewed: [],
      reviewText: "",
      reviewRating: 5,
      reviewsList: [],
      isLoading: false,
      fullPage: true,
      loader: "bars",
      rating: 0,
      actual_rating: 0.0,
      imagesCount: 0,
      dates: {},
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      date: "",
      tmpDate: "",
      other_info: {},
      name: "",
      color: "",
      breeder: "",
      pn: "",
      fll_info: {},
      allTabs: [
        {
          label: "Availability Calendar",
          id: "availability",
        },
        {
          label: "Reviews",
          id: "reviews",
        },
        {
          label: "Videos",
          id: "videos",
        },
      ],
      reviews: [],
      reviews_count: 0,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      activeImage: 0,
      perPage: 10,
      pageNumb: 1,
      mayesh_pull_product_type_id: 3,
      isHardgoods: 0,
      deliveryDateType: 'shpDeliveryDate',
      dateExists: null,
      maxQuoteQuantity: JSON.parse(localStorage.getItem("site_settings")).max_quote_quantity,
    };
  },
  mounted() {
    this.fetchDetails();
    let site_settings = JSON.parse(localStorage.getItem("site_settings"));
    this.mayesh_pull_product_type_id = site_settings.mayesh_pull_product_type_id;
    this.us_message = site_settings.us_flag_message;
    localStorage.setItem("currentPage", this.slug);
    this.addToRecentlyView();
    this.fetchRecentlyViewed();

    this.fetchProductType();

    this.emitter.on("a2c", (payload) => {
      this.addToCartAfterMath(payload);
    });
    this.emitter.on("a2q", (payload) => {
      this.addToCartAfterMath(payload);
    });
    this.emitter.on("cartExpiredReload", () => {
      this.cartExpired();
    });

    this.emitter.on("deletedProduct", () => {
      this.fetchDetails();
    });
  },

  unmounted() {
    this.emitter.off("a2c");
    this.emitter.off("cartExpired");
    this.emitter.off("cartExpiredReload");
    this.emitter.off("deletedProduct");
  },
  methods: {
    ...mapMutations({ changeTab_s: "changeTab" }),
    ...mapMutations(["updateDynamicBreadcrumb"]),

    showSingle() {
      this.show();
    },

    prepareVideo(code, video_id) {
      let htmlText = ''
      if (code && video_id) {
        htmlText = code.replace('[[video_id]]', video_id);
      }

      return htmlText
    },

    changePage(pageNumb) {
      this.pageNumb = pageNumb;
    },

    showMultiple() {
      //this.index = 1 // index of  imgList
      if (this.imagesCount > 0) {
        this.show();
      }
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    cartExpired() {
      this.fetchDetails();
    },
    getBreakdown(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          unit_for_bd = breakdown.bunches * breakdown.stemsBunch;
          if (breakdown.stemsBunch > 1) box_in_unit = "stem";
          else box_in_unit = "bunch";

          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</tbody></table>";
      }
      return str;
    },

    getBreakdownForPulls(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          unit_for_bd = breakdown.bunches;
          if (breakdown.stemsBunch == "bunch") {
            if (breakdown.bunches > 1) box_in_unit = "bunches";
            else box_in_unit = "bunch";
          } else if (breakdown.stemsBunch == "stem") {
            if (breakdown.bunches > 1) box_in_unit = "stems";
            else box_in_unit = "stem";
          }

          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</tbody></table>";
      }
      return str;
    },
    displayReview() {
      this.defaultTab = "reviews";
      var element = this.$refs["review-container"];
      var top = element.offsetTop;
      window.scrollTo(0, top - 150);
      this.changeTab_s("reviews");
    },
    processDateChange() {
      this.date = this.formatDateWithZeroFromStr(this.appendTime(this.tmpDate));
      this.$router.replace({
        path: `/${this.slug}`,
        query: { date: this.date },
      });
      this.fetchDetails();
      this.clearCartComments();
    },
    formatUsDate(dateString) {
      if (!dateString) {
        return "";
      }
      const rawDate = new Date(dateString);
      return (
        rawDate.getMonth() +
        1 +
        "-" +
        rawDate.getDate() +
        "-" +
        rawDate.getFullYear()
      );
    },
    fetchProductType() {
      if (this.isLoggedIn) {
        axios
          .post(
            `${process.env.VUE_APP_API_BASE}auth/getProductType`,
            {
              slug: this.slug,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.isHardgoods = response.data.is_hardgoods;
            if (this.isHardgoods == 1) {
              this.deliveryDateType = 'hgsDeliveryDate';
              if (this.getHgsDeliveryDate) {
                this.tmpDate = this.getHgsDeliveryDate;
              } else if ("date" in this.$route.query) {
                this.tmpDate = this.$route.query.date;
              }
            } else {
              this.deliveryDateType = 'shpDeliveryDate';
              if (this.getShopDeliveryDate) {
                this.tmpDate = this.getShopDeliveryDate;
              } else if ("date" in this.$route.query) {
                this.tmpDate = this.$route.query.date;
              }
            }
            if (this.tmpDate != '' && this.tmpDate != undefined && this.tmpDate != null) {
              this.processDateChange();
            }
          });
      }
    },
    fetchRecentlyViewed() {
      if (this.isLoggedIn) {
        axios
          .post(
            `${process.env.VUE_APP_API_BASE}auth/fetchRecentlyView`,
            {
              slug: this.slug,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.recentlyViewed = response.data.products;
          });
      } else {
        this.recentlyViewed = [];
      }
    },

    addToRecentlyView() {
      if (this.isLoggedIn) {
        axios
          .post(
            `${process.env.VUE_APP_API_BASE}auth/addToRecentlyView`,
            {
              slug: this.slug,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
          });
      }
    },

    fetchDetails() {
      this.isLoading = true;
      let loggedInUser = JSON.parse(localStorage.getItem("loggedinUser"));
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/fetchProduct`,
          {
            slug: this.slug,
            is_sales_rep: loggedInUser ? loggedInUser.is_sales_rep : 0,
            is_e_sales: loggedInUser ? loggedInUser.is_e_sales : 0,
            date: this.date,
            pageNumb: this.pageNumb,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error == false) {
            this.rating = response.data.rating;
            this.actual_rating = response.data.actual_rating;
            this.reviews = response.data.reviews;
            this.reviews_count = response.data.reviews_count;
            this.productOptions = response.data.product;
            this.productDetails = response.data.other_info.product;
            this.quote_quantity = this.productDetails.qty;
            this.quantity_increment = this.productDetails.increment_quantity;
            this.showAddToWishlist = true;

            const crumb = [
              {
                routeName: "Home",
                text: '<i class="fa-solid fa-house-chimney"></i>',
              },
            ];
            if (this.prevUrl.fullPath !== "/") {
              let text = this.prevUrl.name;
              if ("text" in this.prevUrl.meta) {
                text = this.prevUrl.meta.text;
              }

              crumb.push({
                route: this.prevUrl.fullPath,
                text,
              });
            }
            crumb.push({
              route: this.$route.fullPath,
              text: this.productDetails.name,
            });
            this.updateDynamicBreadcrumb(crumb);

            this.imagesCount = this.productDetails.images.length;
            this.imgs = [];
            // get Image
            if (this.productDetails.images) {
              this.productDetails.images.forEach((pImage) => {
                this.imgs.push({ title: pImage.disclaimer, src: pImage.image });
              });
            }
            this.name = response.data.other_info.product.name;
            this.substituteProducts = response.data.other_info.product.substitute_products_active;
            this.crossSellProducts = response.data.other_info.product.cross_sell_products_active;
            this.upsellProducts = response.data.other_info.product.upsell_products_active;
            this.relatedProducts = response.data.other_info.product.related_products_active;
            this.name = response.data.other_info.product.name;
            if (response.data.other_info.product.color)
              this.color = response.data.other_info.product.color.color;
            if (response.data.other_info.product.breeder)
              this.breeder = response.data.other_info.product.breeder.name;
            this.pn = response.data.other_info.product.id;
            this.fll_info = response.data.other_info.fll;
            this.dates = response.data.dates;
            this.other_info = response.data.other_info;
            this.productDetails.product_id = this.productDetails.id;
            this.allTabs = [];
            this.defaultTab = '';
            if (this.fll_info) {
              this.defaultTab = "availability";
              this.allTabs.push(
                {
                  label: "Availability Calendar",
                  id: "availability",
                }
              );
            }
            
            if (this.isLoggedIn && (this.reviews_count > 0 || this.productDetails.allow_reviews)) {
              if (this.defaultTab == '') {
                this.defaultTab = "reviews";
              }
              this.allTabs.push(
                {
                  label: "Reviews",
                  id: "reviews",
                },
              );
            }
            this.videos = response.data.other_info.product.videos
            if (this.videos.length > 0) {
              if (this.defaultTab == '') {
                this.defaultTab = "videos";
              }
              this.allTabs.push(
                {
                  label: "Videos",
                  id: "videos",
                }
              );
            }

            let tab = this.allTabs[0];
            if (this.$route.hash) {
              const hash = this.$route.hash.substring(1);
              tab = _.find(this.allTabs, ["id", hash]);
            }
            // this.changeTab_s('reviews');
            if (tab) {
              setTimeout(() => {
                this.changeTab_s(tab.id);
              }, 500);
            }
            if (this.productOptions.length >= 0)
              this.currentProduct = this.productOptions[0];

            let isDefaultSet = false;
            const allAvailableDates = [];
            this.viewProductToGA()
            if (this.dates != null) {
              this.dates.forEach((availDate) => {
                const formatted = this.appendTimeForBrowser(availDate["delivery_date"]);
                allAvailableDates.push({
                  start: formatted,
                  end: formatted,
                  date: availDate["delivery_date"],
                });

                if (this.date && this.date == availDate["delivery_date"]) {
                  isDefaultSet = true;
                }
              });
              this.availableDates = allAvailableDates;

              if (!isDefaultSet && this.availableDates.length > 0) {
                this.tmpDate = this.availableDates[0].date;
                if (this.tmpDate != '' && this.tmpDate != undefined && this.tmpDate != null) {
                  this.processDateChange();
                }
              }
            }
            if (response.data.disabled_dates != null) {
              response.data.disabled_dates.forEach((disabledDate) => {
                const formatted = this.appendTimeForBrowser(disabledDate);
                this.disabledDates.push({
                  start: formatted,
                  end: formatted,
                  date: disabledDate,
                });
              });
            }
            if (response.data.min_date != null) {
              this.minDate = this.appendTimeForBrowser(response.data.min_date);
            }
            if (response.data.max_date != null) {
              this.maxDate = this.appendTimeForBrowser(response.data.max_date);              
            }
          }

          this.isLoading = false;
        });
    },

    viewProductToGA() {
      const productPrice = this.currentProduct ? parseFloat(this.currentProduct.price_per_unit) : '';
      let productObj = {
        event_category: "purchase",
        event_label: 'view item',
        value: productPrice,
        items: [{
            item_id: this.other_info.product_id,
            item_name: this.other_info.name,
            affiliation: this.currentProduct ? this.currentProduct.farm_name : '',
            item_list_id: this.currentProduct ? this.currentProduct.programtypeId : '',
            item_list_name: this.currentProduct ? this.currentProduct.programtypename : '',
            item_category: this.other_info.category ? this.other_info.category.category : '',
            item_category2: this.other_info.variety ? this.other_info.variety.variety : '',
            item_variant: this.other_info.color ? this.other_info.color.color : '',
            item_category3: this.other_info.grade ? this.other_info.grade.grade : '',
            quantity: this.currentProduct ? this.currentProduct.min_qty : '',
            price: productPrice,
            currency: this.currentProduct ? "USD" : '',
        }]
      };
      this.$gtag.event("view_item", productObj);
    },

    fetchProductReviews() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/fetchProductReviews`,
          {
            slug: this.slug,
            pageNumb: this.pageNumb,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.reviews = response.data.reviews;
        });
    },

    storeReviews() {
      if (this.reviewText.length < 25) {
        this.errorReview = true;
        this.errorText = "Please enter minimum 25 characters";

        return false;
      }
      this.isLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/rateProduct`,
          {
            rating: this.reviewRating,
            slug: this.slug,
            comments: this.reviewText,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          let level = "";
          if (response.data.error) level = "danger";
          else {
            level = "success";
            this.errorReview = false;
            this.errorText = "";
            this.reviewText = "";
          }
          this.showFlashAlert(response.data.message, level);
        });
    },

    updateProduct(product, index) {
      this.currentProduct = product;
      this.selectedIndex = index;
    },
    addToCartAfterMath(response) {
      if (response.level == "success") {
        this.fetchDetails();
        this.updateProduct(this.productOptions[0], 0);
        this.showFlashAlert(response.msg, response.level);
      } else {
        this.showFlashAlert(response.msg, response.level);
      }
    },
    setUnitType(event) {
      this.productDetails.unit_id_dd = parseInt(event.target.value, 10);
    },

    nextImage() {
      var active = this.activeImage + 1;
      if (active >= this.imgs.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    prevImage() {
      var active = this.activeImage - 1;
      if (active < 0) {
        active = this.imgs.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
      this.index = imageIndex;
    },
  },
  computed: {
    ...mapState(["activeTab"]),
    ...mapGetters(["getShopDeliveryDate", "getHgsDeliveryDate"]),

    getProductPrimaryImage() {
      if (this.imagesCount >= 1) {
        return process.env.VUE_APP_IMAGE_URL + this.currentImage;
      } else {
        return (
          process.env.VUE_APP_IMAGE_URL +
          this.getDefaultImage(this.productDetails.product_type_id)
        );
      }
    },
    currentImage() {
      return this.imgs[this.activeImage].src;
    },

    currentDisclaimer() {
      if (
        this.activateImage != undefined &&
        this.imgs[this.index] != undefined
      ) {
        return this.imgs[this.index].title != null
          ? this.imgs[this.index].title
          : "";
      }

      return "";
    },

    user_name() {
      let userDetails = JSON.parse(localStorage.getItem("loggedinUser"));
      if (userDetails) return userDetails.name;
      else return "";
    },

    email() {
      let userDetails = JSON.parse(localStorage.getItem("loggedinUser"));
      if (userDetails) return userDetails.email;
      else return "";
    },
  },
  watch: {
    pageNumb: function () {
      this.fetchProductReviews();
    },

    slug() {
      this.fetchDetails();
    },
    dates() {
      let programDate = this.getShopDeliveryDate;
      if (this.isHardgoods == 1) {
        programDate = this.getHgsDeliveryDate;
      }

      this.dateExists = false;
      if (programDate != null && this.dates != null) {
        this.dates.forEach((date) => {
          if (date.delivery_date == programDate) {
            this.dateExists = true;
            return;
          }
        });
      } else {
        this.dateExists = true;
      }
    },
  },
};
</script>
<style scoped>
.activeThumbnail a.tab {
  border: 3px solid #ccc !important;
}
.tbl_breakdown a,
.tbl_breakdown td {
  color: #fff !important;
}
.table .tbl_breakdown th,
.table .tbl_breakdown td {
  padding: 0 !important;
}

.product-name a {
  text-decoration: none;
}
.font-weight-bold {
  font-weight: bold;
}
td.farm_name, div.farm-name {
  width: 160px;
}
.product-details-row {
  justify-content: space-between;
}
.input-group-append {
  right: 0px;
}
.mt-30 {
  margin-top: 30px;
}
.star-color {
  color: #f37f62;
}

.star-rating li {
  display: inline-block;
  padding: 0px 6px 0px 0px;
}
.login-quote-btn .qoute-btn {
  width: 185px;
  padding: 12px;
  margin-right: -2px !important;
}
.w-208 {
  min-width: 208px;
}
.flex-grow-1 {
  justify-content: space-between;
}
.qoute-btn {
  min-width: 183px;
}

hr.my-4 {
  width: 98%;
  color: #e5e5e5;
  opacity: 0.7;
  height: 0.5px;
}
.reviews-link {
  text-decoration: none;
  cursor: pointer;
}
.d-flex-end {
  justify-content: flex-end;
}
.d-flex-end a {
  min-width: 120px;
}
.popper {
  /* min-width: 280px !important; */
  text-align: center;
}
:deep(.popper) {
  /* min-width: 281px; */
  z-index: 9999;
  text-align: center;
}
:deep(#suggested-substitutes .popper) {
  max-width: 400px !important;
  text-align-last: center !important;
  line-height: 22px !important;
}
.tbl_breakdown,
.tbl_breakdown tr {
  color: #fff;
  border: 1px solid #fff;
}
.us_img_icon {
  width: 40px;
  height: auto;
  margin-left: 15px;
}
.us_img_icon img {
  width: 25px;
  height: auto;
}
.tr-highlight, .div-highlight {
  background-color: #ccc;
  border: 1px solid #ccc !important;
}

.just-right {
  justify-content: flex-end;
}

.single-tab-menu {
  display: inline-block;
}

.recently_viewed,
.recently_viewed .container {
  padding-left: 0px;
  padding-right: 0px;
}
.signle-product-qty-wrapper {
  width: 100%;
}
.signle-product-qty-wrapper :deep(.quantity) {
  width: 76px;
}
.signle-product-qty-wrapper :deep(.btn-theme) {
  margin-left: auto !important;
}

.no-border.no-margin {
    border: 0px !important;
    margin: 0px !important;
}
@media screen and (max-width: 767px) {
  .signle-product-qty-wrapper :deep(.btn-theme),
  .signle-product-unit-wrapper :deep(.btn-theme) {
    width: 100%;
    margin: 10px 0 !important;
  }
}
</style>