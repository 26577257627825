<template>
  <section class="mt-4">
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div
      v-for="(minErrorMsgItem, index) in minErrorMsg"
      :key="index"
      class="alert alert-danger"
    >
      {{ minErrorMsgItem }}.
    </div>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <div id="content" class="col-sm-12">
      <div class="align-items-center justify-content-between mb-4">
        <h1 v-if="isSalesRep || isESales" class="px-0 text-color-orange">
          {{ loggedinUserDetails.fname }} {{ getMetaDetails.page_h1 }}
        </h1>
        <h1 v-else class="px-0">{{ getMetaDetails.page_h1 }}</h1>
        <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      </div>
      <template v-for="(cart, index) in carts" :key="index">
        <div class="row pt-5">
          <div class="col-md-6">
            <div class="shipping-wrapper mb-4">
              <h5 v-if="cart.header.program_type.toUpperCase() == 'SBL'" class="font-weight-bold w-auto" >{{ cart.header.shopping_list_name }}</h5>
              <h5 v-else-if="cart.header.program_type.toUpperCase() == 'DBL2'" class="font-weight-bold w-auto" >{{ cart.header.hol_holiday_name }}</h5>
              <h5 v-else class="font-weight-bold w-auto" >{{ cart.header.program }}</h5>
              <div class="shipping-container border p-4">
                <div class="row">
                  <p class="col-md-4 mb-2 mb-md-3">Delivery Date:</p>
                  <p class="col-md-8 mb-3">{{ cart.header.delivery_date }}</p>
                </div>
                <div class="row">
                  <p class="col-md-4 mb-2 mb-md-3">Cutoff:</p>
                  <p class="col-md-8 mb-3">
                    <template v-for="(cutoff, index) in cart.header.cutoffs" :key="index">
                      {{ cutoff.cutoff }} {{ cutoff.cutoff_timezone }} 
                      <template v-if="cutoff.label">({{ cutoff.label }})<br></template>
                    </template>
                  </p>
                </div>
                <div class="row">
                  <p class="col-md-4 mb-2 mb-md-3">Carrier:</p>
                  <div class="col-md-8 mb-3">
                    <div v-for="(carrier, index) in cart.shipping" :key="index" class="radio">
                      <label>
                        <input v-if="cart.header.shopping_list_id"
                          type="radio"
                          v-model="fields[cart.header.program_id + '_' + cart.header.shopping_list_id]['shipping_method_id']"
                          :name="`${cart.header.program_id}` + '_' + `${cart.header.shopping_list_id}` + '_method'"
                          :value="carrier.shipping_method_id"
                          :checked="(carrier.shipping_method_id == cart.shipping_selected.shipping_method_id && 
                                    carrier.shipping_date == cart.shipping_selected.shipping_date && 
                                    carrier.cutoff == cart.shipping_selected.cutoff)"
                          :disabled="carrier.minimum > cart.subTotal"
                          @change="updateCarrier(cart.header.program_id, cart.header.delivery_date, cart.header.shopping_list_id, cart.header.hol_holiday_id, carrier.shipping_date, carrier.cutoff)"
                        />
                        <input v-else-if="cart.header.hol_holiday_id"
                          type="radio"
                          v-model="fields[cart.header.program_id + '_' + cart.header.hol_holiday_id]['shipping_method_id']"
                          :name="`${cart.header.program_id}` + '_' + cart.header.hol_holiday_id + '_method'"
                          :value="carrier.shipping_method_id"
                          :checked="(carrier.shipping_method_id == cart.shipping_selected.shipping_method_id)"
                          :disabled="carrier.minimum > cart.subTotal"
                          @change="updateCarrier(cart.header.program_id, cart.header.delivery_date, cart.header.shopping_list_id, cart.header.hol_holiday_id, carrier.shipping_date, carrier.cutoff)"
                        />
                        <input v-else-if="cart.header.program_type == 'DBL'"
                          type="radio"
                          v-model="fields[cart.header.program_id]['shipping_method_id']"
                          :name="`${cart.header.program_id}` + '_method'"
                          :value="carrier.shipping_method_id"
                          :checked="(carrier.shipping_method_id == cart.shipping_selected.shipping_method_id)"
                          :disabled="carrier.minimum > cart.subTotal"
                          @change="updateCarrier(cart.header.program_id, cart.header.delivery_date, cart.header.shopping_list_id, cart.header.hol_holiday_id, carrier.shipping_date, carrier.cutoff)"
                        />
                        <input v-else
                          type="radio"
                          v-model="fields[cart.header.program_id]['shipping_method_id']"
                          :name="`${cart.header.program_id}` + '_method'"
                          :value="carrier.shipping_method_id"
                          :checked="(carrier.shipping_method_id == cart.shipping_selected.shipping_method_id && 
                                    carrier.shipping_date == cart.shipping_selected.shipping_date && 
                                    carrier.cutoff == cart.shipping_selected.cutoff)"
                          :disabled="carrier.minimum > cart.subTotal"
                          @change="updateCarrier(cart.header.program_id, cart.header.delivery_date, cart.header.shopping_list_id, cart.header.hol_holiday_id, carrier.shipping_date, carrier.cutoff)"
                        />
                        {{ carrier.shipping_method_name }} ({{ shippingCost(carrier) }})
                        <p style="font-size: 12px" v-if="carrier.checkout_message"></p>
                        <p v-if="carrier.minimum > cart.subTotal" style="font-size: 12px" class="text-danger">
                          Order subtotal must be greater than ${{ carrier.minimum }}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <p class="col-md-4 mb-2 mb-md-3">PO #:</p>
                  <div class="col-md-8 mb-3">
                    <input v-if="cart.header.shopping_list_id"
                      type="text"
                      v-model="fields[cart.header.program_id + '_' + cart.header.shopping_list_id]['po']"
                      @change="updatePo(cart.header.program_id + '_' + cart.header.shopping_list_id)"
                      style="border: 1px solid #ced4da"
                      maxlength="16"
                      class="form-control"
                    />
                    <input v-else-if="cart.header.hol_holiday_id"
                      type="text"
                      v-model="fields[cart.header.program_id + '_' + cart.header.hol_holiday_id]['po']"
                      @change="updatePo(cart.header.program_id + '_' + cart.header.hol_holiday_id)"
                      style="border: 1px solid #ced4da"
                      maxlength="16"
                      class="form-control"
                    />
                    <input v-else
                      type="text"
                      v-model="fields[cart.header.program_id]['po']"
                      @change="updatePo(cart.header.program_id)"
                      style="border: 1px solid #ced4da"
                      maxlength="16"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="row" v-if="cart.header.program_type != 'MDB'">
                  <p class="col-md-4 mb-2 mb-md-3">Comments:</p>
                  <div class="col-md-8">
                    <textarea v-if="cart.header.shopping_list_id"
                      type="text"
                      v-model="fields[cart.header.program_id + '_' + cart.header.shopping_list_id]['comment']"
                      @change="updateComments(cart.header.program_id + '_' + cart.header.shopping_list_id)"
                      rows="3"
                      maxlength="200"
                      class="form-control md-textarea h-auto"
                    ></textarea>
                    <textarea v-else-if="cart.header.hol_holiday_id"
                      type="text"
                      v-model="fields[cart.header.program_id + '_' + cart.header.hol_holiday_id]['comment']"
                      @change="updateComments(cart.header.program_id + '_' + cart.header.hol_holiday_id)"
                      rows="3"
                      maxlength="200"
                      class="form-control md-textarea h-auto"
                    ></textarea>
                    <textarea v-else
                      type="text"
                      v-model="fields[cart.header.program_id]['comment']"
                      @change="updateComments(cart.header.program_id)"
                      rows="3"
                      maxlength="200"
                      class="form-control md-textarea h-auto"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <h5 class="font-weight-bold float-left w-auto">Order Summary</h5>
              </div>
              <div class="col-6">
                <a href="/shopping-cart" class="float-right">Edit Cart <i class="fa-solid fa-pencil"></i></a>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <tbody>
                  <tr v-for="(product, index) in cart.products" :key="index">
                    <td width="15%">
                      <span v-if="cart.header.program_type != 'DBL' && cart.header.program_type != 'DBL2'">
                        <img v-if="product.image" :src="`${imagePath}` + product.image" class="img-thumbnail" :title="product.name" :alt="product.name" />
                        <img v-else :src="`${imagePath}` + product.default_image" class="img-thumbnail" :title="product.name" :alt="product.name" />
                      </span>
                      <span v-else>
                        <img v-if="product.product_image" :src="product.product_image" class="img-thumbnail" :title="product.product_name" :alt="product.product_name" />
                        <img v-else :src="`${imagePath}` + product.default_image" class="img-thumbnail" :title="product.product_name" :alt="product.product_name" />
                      </span>
                    </td>
                    <td v-if="cart.header.program_type != 'DBL' && cart.header.program_type != 'DBL2'">{{ product.name }}</td>
                    <td v-else>{{ product.product_name }}</td>
                    <td class="text-end" v-if="product.price_after_coupon > 0"><span class="discounted_price">${{ product.total }}</span>${{ product.price_after_coupon }}</td>
                    <td class="text-end" v-else>${{ product.total }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table">
                <tbody>
                  <tr>
                    <td>Sub-Total</td>
                    <td class="text-end"><b>${{ cart.subTotal }}</b></td>
                  </tr>
                  <tr v-if="cart.discount > 0">
                    <td>Coupon ({{ cart.coupon_code }})</td>
                    <td class="text-end"><b>-${{ cart.discount }}</b></td>
                  </tr>
                  <tr v-if="cart.taxes > 0">
                    <td>Taxes</td>
                    <td class="text-end"><b>${{ cart.taxes }}</b></td>
                  </tr>
                  <tr>
                    <td>{{ cart.shipping_selected.shipping_method_name }}</td>
                    <td class="text-end"><b>{{ shippingCost(cart.shipping_selected) }}</b></td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td class="text-end"><b>${{ cart.total }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-html="cart.header.disclaimer" class="mb-3"></div>
          </div>
        </div>
        <hr v-if="cartsCount > 1" class="my-5" />
      </template>
      <template v-if="cartsCount < 1">
        <div id="empty_cart">Currently, your cart is empty</div>
      </template>
      <div v-if="!is_sales_rep && !is_e_sales && cartsCount > 0" class="panel border p-4">
        <div class="row align-items-center justify-content-center">
          <label class="col-12 col-md-auto col-lg-auto control-label mb-2 mb-md-0 text-center text-md-left" for="input-coupon"><h5>Have a coupon?</h5></label>
          <div class="col-12 col-md">
            <div class="input-group flex-nowrap">
              <input type="text" name="coupon" v-model="coupon_code" placeholder="Enter Your Coupon Here" id="input-coupon" class="form-control" />
            </div>
          </div>
          <div class="col-12 col-md-auto mt-2 mt-md-0 text-center">
            <span class="input-group-btn">
              <input type="button" value="Apply Coupon" @click="applyCoupon()" id="button-coupon" data-loading-text="Loading..." class="btn btn-primary w-100 w-md-auto" />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="cartsCount > 0">
        <div class="row align-items-center pt-0 pt-md-3">
          <div class="col-12 col-md-auto text-center text-md-start pb-3 pb-md-0">
            <a href="/shopping-cart">Return To Cart</a>
          </div>
          <div class="col-12 col-md text-center text-md-end">
            <span v-if="isEnrolledLoyalty && loyaltyMessage != ''" class="d-block d-md-inline pe-0 pe-md-4 pb-3 pb-md-0 peach-color">{{ loyaltyMessage }}</span>
            <button class="btn btn-theme w-auto" @click="placeOrder()" :disabled="!canPlaceOrder" id="place_order">Place Order</button>
          </div>
        </div>
        <!-- Button trigger modal -->
        <modal v-if="displayModal" :closeFn="hideModal">
          <p class="text-danger" id="failed_cart_msg">The product(s) listed below can not be purchased as their cutoff time has passed.</p>
          <div v-for="(cart, index) in failed_carts" :key="index">
            <h4>{{ cart.program_name }}</h4>
            <table class="table table-responsive">
              <thead class="thead-light">
                <th>Image</th>
                <th>Product</th>
                <th>Total</th>
              </thead>
              <tbody>
                <tr v-for="(product, index) in cart.products" :key="index">
                  <td>
                    <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
                    <img v-else :src=" `${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.name" />
                  </td>
                  <td>{{ product.name }}</td>
                  <td>${{ product.total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </modal>
      </div>
    </div>
    <div id="page_content_below" class="mt-10 content-wrapper" v-if="!!getMetaDetails.description_below_dynamic" v-html="getMetaDetails.description_below_dynamic"></div>
  </section>
</template>

<script>
import { FlashAlert, Modal, PageMetadata } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { misc } from "@/mixins";
import axios from "axios";

export default {
  name: "CheckoutPage",
  mixins: [misc],
  components: { FlashAlert, Modal, PageMetadata },
  data() {
    return {
      carts: [],
      is_sales_rep: false,
      cartsCount: 1,
      coupon_code: "",
      isLoading: false,
      fullPage: true,
      loader: "bars",
      fields: {},
      displayModal: false,
      modalDetails: {},
      cartsField: {},
      modal_type: {},
      failed_carts: {},
      validCartCount: 0,
      deliveryDate: "",
      canPlaceOrder: true,
      selected_shipping: {},
      minErrorMsg: Array(),
      loyaltyPoints: 0,
      loyaltyMessage: '',
    };
  },
  mounted() {
    let loggedInUser = JSON.parse(localStorage.getItem("loggedinUser"));
    this.is_sales_rep = loggedInUser.is_sales_rep;
    this.is_e_sales = loggedInUser.is_e_sales;
    this.getCarts();
    localStorage.setItem("currentPage", "checkout");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("deletedProduct", () => {
      this.hideLoader();
    });
    this.emitter.on("cartExpiredReload", () => {
      this.cartExpired();
    });
  },
  unmounted() {
    this.emitter.off("a2c");
    this.emitter.off("deletedProduct");
    this.emitter.off("cartExpiredReload");
  },
  computed: {
    ...mapGetters(["loggedinUserDetails", "isSalesRep", "isESales", "isEnrolledLoyalty"]),

    name() {
      return localStorage.getItem("name");
    },
  },
  methods: {
    ...mapActions(["fetchCartAndMeta"]),

    updatePo(programId) {
      localStorage.setItem(
        "cartPoDetails" + "_" + programId,
        this.fields[programId]["po"]
      );
    },

    updateComments(programId) {
      localStorage.setItem(
        "cartCommentsDetails" + "_" + programId,
        this.fields[programId]["comment"]
      );
    },

    applyCoupon() {
      this.isLoading = true;
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/applyCoupon",
            {
              coupon_code: this.coupon_code,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error == false) {
              this.showFlashAlert("Coupon applied successfully", "success");
            } else {
              this.showFlashAlert(response.data.message, "error");
            }
            this.getCarts();
            this.isLoading = false;
          });
      } catch {
        console.log("Something went wrong");
      }
    },

    cartExpired() {
      this.getCarts();
    },
    getCarts() {
      this.isLoading = true;
      let cartTypes = this.$route.query.type;
      let shoppingLists = this.$route.query.list;
      let carts = cartTypes.split(",");
      let shoppingListIds = Array();
      if (shoppingLists != '' && shoppingLists != undefined) {
        shoppingListIds = shoppingLists.split(",");
      }
      let holHolidays = this.$route.query.hol_holiday;
      let holHolidayIds = Array();
      if (shoppingLists != '' && holHolidays != undefined) {
        holHolidayIds = holHolidays.split(",");
      }
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/checkout",
            {
              carts: carts,
              shopping_list_ids: shoppingListIds,
              hol_holiday_ids: holHolidayIds,
              is_sales_rep: this.isSalesRep,
              is_e_sales: this.isESales,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.carts = response.data.carts;
            this.coupon_code = response.data.coupon_code;
            this.loyaltyPoints = response.data.loyalty_points;
            this.loyaltyMessage = response.data.loyalty_message;
            if (this.carts.length == undefined)
              this.cartsCount = Object.getOwnPropertyNames(
                response.data.carts
              ).length;
            else this.cartsCount = this.carts.length;
            for (const progId in response.data.carts) {
              if (this.deliveryDate == "") {
                this.deliveryDate =
                  this.carts[progId]["header"]["delivery_date"];
              }
              if (this.carts[progId]["shipping_selected"] != null) {
                if (this.canPlaceOrder != false) {
                  this.canPlaceOrder = true;
                }
                this.fields[progId] = {
                  shipping_method_id: 
                    this.carts[progId]["shipping_selected"]["shipping_method_id"],
                  po: localStorage.getItem("cartPoDetails" + "_" + progId)
                    ? localStorage.getItem("cartPoDetails" + "_" + progId)
                    : "",
                  comment: localStorage.getItem("cartCommentsDetails" + "_" + progId)
                    ? localStorage.getItem("cartCommentsDetails" + "_" + progId)
                    : "",
                };
                let selectShipping = this.carts[progId]["shipping_selected"].minimum.toString();
                let cartSubTotal = this.carts[progId].subTotal.toString();
                if (
                  this.canPlaceOrder == true &&
                  parseFloat(selectShipping.replace(/,/g, "")) >
                    parseFloat(cartSubTotal.replace(/,/g, ""))
                ) {
                  this.canPlaceOrder = false;
                  this.minErrorMsg.push(
                    "Please review your cart totals, you have not met the minimum for " +
                      this.carts[progId]["header"].program
                  );
                }

                let customerOrderMinimumMessage = '';
                let customerOrderMinimum = this.carts[progId]["customer_order_minimum"];
                if (customerOrderMinimum > parseFloat(cartSubTotal.replace(/,/g, ""))) {
                  this.canPlaceOrder = false;
                  let link = '';
                  if (this.carts[progId]["header"]["program"] == 'DBL') {
                    link = 'dutch-direct-boxlots';
                  } else {
                    if (this.carts[progId]["header"]["program"] == 'HGS') {
                      link = '/hardgoods';
                    } else {
                      if (this.carts[progId]["header"]["program"] == 'MMT') {
                        link = '/luxe-blooms-grocery';
                      } else {
                        if (this.carts[progId]["header"]["program"] == 'WSP') {
                          link = '/specials';
                        } else {
                          link = '/shop?filter_bt=1&filter_program=' + this.carts[progId]["header"]["program_id"];
                        }
                      }
                    }
                  }

                  if (link != '') {
                    link = ' <a href="' + link + '" class="text-white text-decoration-underline">Continue Shopping</a>';
                  }

                  customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum on the ' + this.carts[progId]["header"]["program"] + ' orders - you are sooo close!';
                  if (parseFloat(cartSubTotal.replace(/,/g, "")) < 100) {
                    customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum for ' + this.carts[progId]["header"]["program"] + ' orders.';
                  } else {
                    if (parseFloat(cartSubTotal.replace(/,/g, "")) < 200) {
                      customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum on the ' + this.carts[progId]["header"]["program"] + ' orders - just a few more boxes will do the trick!';
                    }
                  }
                  customerOrderMinimumMessage = customerOrderMinimumMessage + link;
                  this.showFlashAlert(customerOrderMinimumMessage, "error");
                }
              }
            }

            this.placeBeginCheckoutOnGA()
            this.isLoading = false;
          });
      } catch {
        console.log("Something went wrong");
      }
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.getCarts();
    },
    hideModal() {
      this.displayModal = false;
      if (!this.validCartCount) {
        this.$router.push({ name: "Shop" });
      }
    },

    placeOrder() {
      this.isLoading = true;
      let cartTypes = this.$route.query.type;
      let cartsArray = cartTypes.split(",");
      let cartIds = [];
      for (let cart in this.carts) {
        cartIds.push(cart);
        this.cartsField[cart] = { fiels: this.fields[cart] };
      }

      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/order",
            {
              carts: cartsArray,
              cartFields: this.cartsField,
              is_sales_rep: this.isSalesRep,
              is_e_sales: this.isESales,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.fetchCartAndMeta();

            this.isLoading = false;
            if (response.data.error) {
              if (response.data.failed_carts) {
                this.failed_carts = response.data.failed_carts;
                this.validCartCount = response.data.validCartCount;
                this.modal_type = "failed_carts";
                this.displayModal = true;
              } else {
                this.showFlashAlert(response.data.message, "error");
              }
            } else {
              this.placeOrderOnQAnalytics(response.data.transactionIds)
              this.showFlashAlert("Order placed successfully", "success");
              setTimeout(() => {
                window.location.href = "/order-thank-you";
              }, 500);
            }

            this.clearCartComments();
          });
      } catch {
        console.log("Something went wrong");
      }
    },
    placeOrderOnQAnalytics_from_current_branch() {
      this.$gtag.event("begin_checkout", {
        event_category: "checkout",
        event_label: "begin_checkout",
        value: 100.0,
        items: [
          {
            id: "1128965",
            name: "Carnation Putumayo Beige Fancy",
            list_name: "Shop",
            brand: "Prebooks",
            category: "Carnation",
            variant: "Beige",
            list_position: 1,
            quantity: 5,
            price: "100.00",
          },
        ],
        coupon: "",
      });

      this.$gtag.purchase({
        transaction_id: "10",
        affiliation: "mayesh.com",
        value: 100,
        currency: "USD",
        tax: 8,
        shipping: 20,
        items: [
          {
            id: "1128965",
            name: "Carnation Putumayo Beige Fancy",
            list_name: "Shop",
            brand: "Prebooks",
            category: "Carnation",
            variant: "Beige",
            list_position: 1,
            quantity: 5,
            price: "100.00",
          },
        ],
      });
    },

    placeBeginCheckoutOnGA() {
      let cartIds = []
      for (let cart in this.carts) {
        cartIds.push(cart);
      }

      for (let counter = 0; counter < cartIds.length; counter++) {
        let cart = this.carts[cartIds[counter]]
        const items = [];
        cart.products.forEach((prod) => {
          items.push({
            item_id: prod.product_id,
            item_name: prod.name,
            affiliation: prod.farm_name,
            item_list_id: prod.programtypeId,
            item_list_name: prod.programtypeName,
            item_category: prod.category,
            item_category2: prod.variety,
            item_variant: prod.color,
            item_category3: prod.grade,
            quantity: parseInt(prod.quantity),
            price: parseFloat(prod.unit_price),
            price_after_coupon: prod.price_after_coupon > 0 ? parseFloat(prod.price_after_coupon) : parseFloat(prod.unit_price),
            currency: "USD",
          });
        });

        this.$gtag.event("begin_checkout", {
          event_category: "purchase",
          event_label: "begin checkout",
          sub_total: parseFloat(cart.subTotal),
          coupon: cart.coupon_code,
          discount_percent: parseFloat(cart.coupon_discount),
          discount_amount: parseFloat(cart.discount),
          value: parseFloat(cart.total),
          shipping: parseFloat(cart.shipping_selected),
          discount: cart.discount,
          tax: parseFloat(cart.taxes),
          currency: "USD",
          items: items,
        });
        
      }
    },

    placeOrderOnQAnalytics(cartSuccess) {
      for (const progId in cartSuccess) {
        const cart = this.carts[progId];
        const items = [];
        let i = 0;
        cart.products.forEach((prod) => {
          i++;
          items.push({
            index: i,
            item_id: prod.product_id,
            item_name: prod.name,
            affiliation: prod.farm_name,
            item_list_id: prod.programtypeId,
            item_list_name: prod.programtypeName,
            item_category: prod.category,
            item_category2: prod.variety,
            item_variant: prod.color,
            item_category3: prod.grade,
            quantity: parseInt(prod.quantity),
            price: parseFloat(prod.unit_price),
            price_after_coupon: prod.price_after_coupon > 0 ? parseFloat(prod.price_after_coupon) : parseFloat(prod.unit_price),
            currency: "USD",
          });
        });

        this.$gtag.purchase({
          sub_total: cart.subTotal,
          coupon: cart.coupon_code,
          discount_percent: cart.coupon_discount,
          discount_amount: cart.discount,
          value: parseFloat(cart.total),
          shipping: parseFloat(cart.shipping_selected),
          tax: parseFloat(cart.taxes),
          currency: "USD",
          transaction_id: cartSuccess[progId],
          items: items,
        });
      }
    },
    updateCarrier(programId, theDeliveryDate, shopping_list_id, hol_holiday_id, theShippingDate, theCutoff) {
      this.isLoading = true;
      this.canPlaceOrder = true;
      this.minErrorMsg = Array();
      let cartTypes = this.$route.query.type;
      let carts = cartTypes.split(",");
      let shoppingLists = this.$route.query.list;
      let shoppingListIds = Array();
      if (shoppingLists != '' && shoppingLists != undefined) {
        shoppingListIds = shoppingLists.split(",");
      }
      let holHolidays = this.$route.query.hol_holiday;
      let holHolidayIds = Array();
      if (holHolidays != '' && holHolidays != undefined) {
        holHolidayIds = holHolidays.split(",");
      }
      let currentId = '';
      if (shopping_list_id != '' && shopping_list_id != undefined) {
        currentId = '_' + shopping_list_id;
      } else {
        if (hol_holiday_id != '' && hol_holiday_id != undefined) {
          currentId = '_' + hol_holiday_id;
        }
      }

      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/updateCarrier",
            {
              program_id: programId,
              shopping_list_id: shopping_list_id,
              hol_holiday_id: hol_holiday_id,
              shipping_method_id: this.fields[programId + currentId]['shipping_method_id'],
              delivery_date: theDeliveryDate,
              shipping_date: theShippingDate,
              cutoff: theCutoff
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.isLoading = false;
            this.showFlashAlert("Carrier updated successfully", "success");
            axios
              .post(
                process.env.VUE_APP_API_BASE + "auth/checkout",
                {
                  carts: carts,
                  shopping_list_ids: shoppingListIds,
                  hol_holiday_ids: holHolidayIds,
                  is_sales_rep: this.isSalesRep,
                  is_e_sales: this.isESales,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                this.carts = response.data.carts;
                this.fetchCartAndMeta();
                for (const progId in response.data.carts) {
                  this.fields[progId] = {
                    shipping_method_id: 
                      this.carts[progId]["shipping_selected"][
                        "shipping_method_id"
                      ],
                    po: localStorage.getItem("cartPoDetails" + "_" + progId)
                      ? localStorage.getItem("cartPoDetails" + "_" + progId)
                      : "",
                    comment: localStorage.getItem("cartCommentsDetails" + "_" + progId) 
                            ? localStorage.getItem("cartCommentsDetails" + "_" + progId) 
                            : "",
                  };

                  let selectShipping = this.carts[progId]["shipping_selected"].minimum.toString();
                  let cartSubTotal = this.carts[progId].subTotal.toString();
                  if (
                    this.canPlaceOrder == true &&
                    parseFloat(selectShipping.replace(/,/g, "")) >
                      parseFloat(cartSubTotal.replace(/,/g, ""))
                  ) {
                    this.canPlaceOrder = false;
                    this.minErrorMsg.push(
                      "Please review your cart totals, you have not met the minimum for " +
                        this.carts[progId]["header"].program
                    );
                  }

                  let customerOrderMinimumMessage = '';
                  let customerOrderMinimum = this.carts[progId]["customer_order_minimum"];
                  if (customerOrderMinimum > parseFloat(cartSubTotal.replace(/,/g, ""))) {
                    this.canPlaceOrder = false;
                    let link = '';
                    if (this.carts[progId]["header"]["program"] == 'DBL') {
                      link = 'dutch-direct-boxlots';
                    } else {
                      if (this.carts[progId]["header"]["program"] == 'HGS') {
                        link = '/hardgoods';
                      } else {
                        if (this.carts[progId]["header"]["program"] == 'MMT') {
                          link = '/luxe-blooms-grocery';
                        } else {
                          if (this.carts[progId]["header"]["program"] == 'WSP') {
                            link = '/specials';
                          } else {
                            link = '/shop?filter_bt=1&filter_program=' + this.carts[progId]["header"]["program_id"];
                          }
                        }
                      }
                    }

                    if (link != '') {
                      link = ' <a href="' + link + '" class="text-white text-decoration-underline">Continue Shopping</a>';
                    }

                    customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum on the ' + this.carts[progId]["header"]["program"] + ' orders - you are sooo close!';
                    if (parseFloat(cartSubTotal.replace(/,/g, "")) < 100) {
                      customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum for ' + this.carts[progId]["header"]["program"] + ' orders.';
                    } else {
                      if (parseFloat(cartSubTotal.replace(/,/g, "")) < 200) {
                        customerOrderMinimumMessage = 'Please note the $' + customerOrderMinimum + ' minimum on the ' + this.carts[progId]["header"]["program"] + ' orders - just a few more boxes will do the trick!';
                      }
                    }
                    customerOrderMinimumMessage = customerOrderMinimumMessage + link;
                    this.minErrorMsg.push(customerOrderMinimumMessage);
                  }
                }

                this.isLoading = false;
                this.showFlashAlert(
                  "Carrier updated successfully",
                  "success"
                );
              });
          });
      } catch {
        console.log("Something went wrong");
      }
    },
    shippingCost(selected_shipping) {
      if (selected_shipping.cost > 0) {
        return "$" + parseFloat(selected_shipping.cost).toFixed(2);
      } else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 1) {
        if (selected_shipping.tbd_message) return selected_shipping.tbd_message;
        else return "TBD";
      } else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 0)
        return "FREE";
    },
  },
};
</script>
<style scoped>
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}

.pull-right {
  float: right;
}

.modal-footer {
  justify-content: center !important;
}
.discounted_price {
  margin: 9px;
  text-decoration: line-through;
}
.text-color-orange, .text-orange {
  color: #f48062;
}
.w-90 {
  max-width: 560px !important;
  width: 100% !important;
  border: 1px solid #ccc;
}
.w-90 th {
  color: #000;
  background-color: #ccc;
}
@media (max-width: 767px) {
  #place_order {
    width: 100% !important;
  }
}
</style>