<template>
  <section>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="content" class="col-sm-12">
      <h1 class="mb-4 mb-md-5 px-0 pb-0">{{ getMetaDetails.page_h1 }}</h1>
      <div class="row login-sections">
        <div class="col-md-5 mb-4 mb-md-3 login-section-left">
          <p
            class="
              mt-0
              mb-4 mb-md-5
              h6
              text-transform-reset
              letter-spacing-reset
            "
            v-if="!!getMetaDetails.description"
            v-html="getMetaDetails.description"
          ></p>
          <form @submit.prevent="doLogin">
            <div class="form-floating">
              <input
                type="text"
                placeholder="E-Mail Address"
                v-model="form.email"
                class="form-control"
              />
              <label class="control-label" for="input-email"
                >E-Mail Address</label
              >
            </div>
            <div class="buttons">
              <input
                type="submit"
                class="btn btn-default"
                @click="ForgotPassword"
                value="Change Password"
              />
              <a href="/login" class="btn btn-default ms-md-3">Back</a>
            </div>
          </form>
        </div>
        <div
          id="column-right"
          class="col-md-7 mb-3 login-section-right mt-5 mt-md-0"
        >
          <div class="mt-md-auto">
            <a href="/login" class="btn btn-primary">Login</a><br />
            <a href="/register" class="btn btn-primary mt-3">Register</a>
          </div>
        </div>
      </div>
      <div
        id="page_content_below"
        class="mt-10 content-wrapper"
        v-if="!!getMetaDetails.description_below_dynamic"
        v-html="getMetaDetails.description_below_dynamic"
      ></div>
    </div>
  </section>
</template>

<script>
import { FlashAlert, PageMetadata } from "@/components";
import axios from "axios";
import { misc } from "@/mixins";

export default {
  name: "ForgotPassword",
  mixins: [misc],
  components: {
    FlashAlert,
    PageMetadata,
  },
  data() {
    return {
      form: {
        password: "",
        confirm_password: "",
      },
    };
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('/account');
    }
    localStorage.setItem("currentPage", "forgot-password");
    this.fetchMetaDetails();
  },
  methods: {
    ForgotPassword() {
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/forgot-password",
          {
            email: this.form.email,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          if (response.data.error === true) {
            this.showFlashAlert(response.data.message, "error");
          } else {
            // this.showFlashAlert(, 'success');
            //
            this.emitter.emit("displayFlash", {
              message: response.data.message,
              level: "success",
            });
            this.$router.push({ name: "Login" });
            this.form.email = "";
          }
        });
    },
  },
};
</script>