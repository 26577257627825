<template>
  <section>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="content" class="col-sm-12">
      <h1 class="mb-4 mb-md-5 px-0 pb-0">{{ getMetaDetails.page_h1 }}</h1>
      <div class="row login-sections">
        <div class="col-md-5 mb-4 mb-md-3 login-section-left">
          <p
            class="
              mt-0
              mb-4 mb-md-5
              h6
              text-transform-reset
              letter-spacing-reset
            "
            v-if="!!getMetaDetails.description"
            v-html="getMetaDetails.description"
          ></p>
          <form @submit.prevent="doLogin">
            <div class="form-floating">
              <input
                type="password"
                placeholder="Password"
                v-model="form.password"
                class="form-control"
              />
              <label class="control-label" for="input-email">Password</label>
            </div>
            <div class="form-floating">
              <input
                type="password"
                placeholder="Confirm Password"
                v-model="form.confirm_password"
                class="form-control"
              />
              <label class="control-label" for="input-password"
                >Confirm Password</label
              >
            </div>
            <input
              type="submit"
              class="btn btn-primary"
              @click="changePassword"
              value="Change Password"
            />
          </form>
        </div>
        <div
          id="column-right"
          class="col-md-7 mb-3 login-section-right mt-5 mt-md-0"
        >
          <p
            class="
              mt-0
              mb-4 mb-md-5
              h6
              text-transform-reset
              letter-spacing-reset
            "
          >
            If you have other information you would like to update, <br />
            Click the button below to fill out the form.
          </p>
          <div class="mt-md-auto">
            <a href="/update-information" class="btn btn-primary"
              >Update My Information</a
            >
          </div>
        </div>
      </div>
      <div
        id="page_content_below"
        class="content-wrapper mt-10"
        v-if="!!getMetaDetails.description_below_dynamic"
        v-html="getMetaDetails.description_below_dynamic"
      ></div>
    </div>
  </section>
</template>

<script>
import { FlashAlert, PageMetadata } from "@/components";
import axios from "axios";
import { misc } from "@/mixins";

export default {
  name: "ChangePassword",
  mixins: [misc],
  components: { FlashAlert, PageMetadata, },
  data() {
    return {
      form: {
        password: "",
        confirm_password: "",
      },
    };
  },
  mounted() {
    localStorage.setItem("currentPage", "change-password");
    this.fetchMetaDetails();
  },
  methods: {
    // ...mapMutations(["storeUserDetails", "storeQuote", "clearCartMeta", "clearCart", "clearQuote"]),
    // ...mapActions(["fetchCartAndMeta", "addToQuoteBulk", "logout"]),
    // doLogout() {
    //   this.logout();
    //   this.clearCartMeta();
    //   this.clearCart();
    //   this.clearQuote()
    //   this.emitter.emit("updateMegamenu");
    //   this.$router.push({ name: "Login" });
    // },
    changePassword() {
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/change-password",
          {
            password: this.form.password,
            confirm_password: this.form.confirm_password,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error === true) {
            this.showFlashAlert(response.data.message, "error");
          } else {
            this.showFlashAlert(response.data.message, "success");
            this.form.password = "";
            this.form.confirm_password = "";
          }
        });
    },
  },
};
</script>