<template>
  <PageMetadata v-if="!embed" />
  <PageCanonical v-if="prods.total > 0" :canonical="{ totalRecords: prods.total }" />
  <section>
    <flash-alert v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <template v-if="!embed">
      <h1 class="pb-0 mb-4 px-0">{{ getMetaDetails.page_h1 }}</h1>
      <p v-if="!isLoggedIn" class="font-weight-bold pb-3">
        NOTE: To expedite your quote, please <a href="/login">log in</a>.
      </p>
      <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
    </template>
    <section class="mt-4">
      <div>
        <div class="entire row gx-3 my-40">
          <div class="offset-lg-2 col-md-auto mb-3 mb-md-0 pt-md-2">
            <span
              ><input
                type="checkbox"
                class="entire_library_input ms-0"
                value="1"
                v-model="filters.isEntire"
                @change="entireChange"
            /></span>
            <span class="entire_library">Entire Library</span>
          </div>
          <!-- <select class="form-select" v-model="filters.bySeason">
              <option value="">Filter by Season</option>
              <option value="mar_apr_may">Spring</option>
              <option value="jun_jul_aug">Summer</option>
              <option value="sep_oct_nov">Fall</option>
              <option value="dec_jan_feb">Winter</option>
            </select> -->
          <div class="col-6 col-md-3 mb-3 mb-md-0">
            <select
              class="form-select"
              v-model="filters.byMonth"
              @change="byMonthChange"
            >
              <option value="">Filter by Month</option>
              <option value="jan">January</option>
              <option value="feb">February</option>
              <option value="mar">March</option>
              <option value="apr">April</option>
              <option value="may">May</option>
              <option value="jun">June</option>
              <option value="jul">July</option>
              <option value="aug">August</option>
              <option value="sep">September</option>
              <option value="oct">October</option>
              <option value="nov">November</option>
              <option value="dec">December</option>
            </select>
          </div>
          <div class="col-6 col-md-3">
            <!-- <delivery-date-picker v-model:dateProp="filters.tmpDate" @deliveryDateChanged="processDateChange" :availableDates="availableDates" /> -->
            <date-picker 
              v-model:dateProp="filters.tmpDate"
              @pickerDateChanged="processDateChange"
              :minDate="minDate" 
              :maxDate="maxDate" 
              :disabledDates="disabledDates"
              :key="datePickerKey"
            />
          </div>
        </div>
        <div class="row my-4 align-items-center">
          <div class="offset-lg-2 col-lg-3 mb-0 text-center text-lg-start">
            <b
              ><label class="mb-0 p-0"
                >Total Products:
                <span id="total_products">{{ prods.total }}</span></label
              ></b
            >
          </div>
          <div class="col-lg-7 mt-2 mt-lg-0">
            <div class="row gx-3 justify-content-between">
              <div class="mt-0 col-5 col-sm-auto">
                <div
                  class="
                    delivery-address
                    d-flex
                    align-items-center
                    justify-content-between
                    mb-2 mb-sm-0
                  "
                >
                  <label class="mb-0 p-0" for=""><b>Show:</b></label>
                  <div class="d-flex flex-grow-1">
                    <div
                      class="
                        d-flex
                        justify-content-around
                        align-items-center
                        p-0
                      "
                    >
                      <select
                        class="form-select"
                        v-model.number="filters.perPage"
                      >
                        <option value="16">16</option>
                        <option value="48">48</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-0 col-7 col-sm-auto">
                <div
                  class="
                    delivery-address
                    d-flex
                    align-items-center
                    justify-content-between
                    mb-2 mb-sm-0
                  "
                >
                  <label class="mb-0 p-0" for=""><b>Sort By:</b></label>
                  <div class="d-flex flex-grow-1">
                    <select class="form-select" v-model="filters.sortBy">
                      <option value="Name-ASC">Name (A - Z)</option>
                      <option value="Name-DESC">Name (Z - A)</option>
                      <option v-if="isLoggedIn" value="Price-ASC">
                        Price/Unit Asc
                      </option>
                      <option v-if="isLoggedIn" value="Price-DESC">
                        Price/Unit Desc
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="
                  col-12 col-sm-auto
                  mt-1 mt-sm-0
                  align-self-center
                  text-center
                  ms-md-2
                "
              >
                <div id="grid-list-btn-container" class="d-inline-flex">
                  <Popper :arrow="true" :hover="true" content="List">
                    <button
                      type="button"
                      id="list-view"
                      @click="changeView('list')"
                      class="btn btn-default"
                    >
                      <i class="fa-solid fa-table-list"></i>
                    </button>
                  </Popper>

                  <Popper :arrow="true" :hover="true" content="Grid">
                    <button
                      type="button"
                      id="grid-view"
                      @click="changeView('grid')"
                      class="btn btn-default"
                    >
                      <i class="fa-solid fa-table-cells-large"></i>
                    </button>
                  </Popper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="displayProducts" class="row">
          <div class="col-lg-2">
            <div class="sidebar">
              <div class="mb-4 text-center text-md-start">
                <input
                  type="button"
                  id="reset-btn"
                  class="btn btn-theme"
                  value="Reset"
                  @click="resetFilters"
                />
              </div>

              <div class="mb-4 input-group" v-if="!embed">
                <input
                  type="text"
                  class="form-control width-0"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  v-model="filters.search"
                  @keyup.enter="applySearch"
                />
                <button
                  class="btn btn-theme"
                  type="button"
                  id="button-addon2"
                  @click="applySearch"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>

              <div
                v-for="(accordian, accordKey) in filterSection"
                :class="['mb-4', accordKey == currentAccordion ? 'open' : '']"
                :key="accordKey"
              >
                <div class="sidebar-heading" v-on:click="expand(accordKey)">
                  <p>{{ accordian.label }} <i class="fa-solid fa-plus"></i></p>
                </div>
                <template
                  v-for="(filterss, filterGroup) in accordian"
                  :key="filterGroup"
                >
                  <template v-if="filterGroup != 'label'">
                    <div
                      class="filter-container pt-0"
                      :class="{ 'mh-100': filterGroup == 'filter_color_combo' }"
                    >
                      <ul
                        class="filters"
                        :class="{
                          'bubble_color_wrapper my-1':
                            filterGroup == 'filter_color_combo',
                        }"
                      >
                        <li
                          :class="{
                            bubble_color: filterGroup == 'filter_color_combo',
                          }"
                          v-for="(filter, key) in filterss"
                          :key="key"
                        >
                          <a
                            v-if="'image' in filter && filter.image != '' && filter.image != null"
                            href="#"
                            @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            "
                          >
                            <Popper
                              :arrow="true"
                              :hover="true"
                              :content="filter.label"
                            >
                              <!-- <img
                                :class="[
                                  'filter-color',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]"
                                :src="`${imagePath}` + filter.image"
                              /> -->

                              <span
                                :class="[
                                  'filter-color',
                                  'filter-image',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]"
                                :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-image':
                                    `${imagePath}` + filter.image,
                                }"
                                ><img
                                  :class="[
                                    'filter-color',
                                    filter.border,
                                    filterGroup in filters.criteria &&
                                    filter.id in filters.criteria[filterGroup]
                                      ? filter.checkmark
                                      : '',
                                  ]"
                                  :src="`${imagePath}` + filter.image"
                              /></span>
                            </Popper>
                          </a>
                          <a
                            v-else-if="'hex' in filter && filter.hex != '' && filter.hex != null"
                            href="#"
                            @click.prevent="
                              setFilterCriteria({
                                group: filterGroup,
                                value: filter.id,
                                label: filter.label,
                              })
                            "
                          >
                            <Popper
                              :arrow="true"
                              :hover="true"
                              :content="filter.label"
                            >
                              <span
                                :class="[
                                  'filter-color',
                                  filter.border,
                                  filterGroup in filters.criteria &&
                                  filter.id in filters.criteria[filterGroup]
                                    ? filter.checkmark
                                    : '',
                                ]"
                                class=""
                                :style="{
                                  height: '22px',
                                  width: '22px',
                                  display: 'block',
                                  'border-radius': '50%',
                                  'background-color': filter.hex,
                                }"
                              ></span>
                            </Popper>
                          </a>
                          <a v-else-if="'href' in filter" :href="filter.href">
                            {{ filter.label }}
                          </a>
                          <template v-else>
                            <input
                              type="checkbox"
                              :value="filter.id"
                              :checked="
                                filterGroup in filters.criteria &&
                                filter.id in filters.criteria[filterGroup]
                              "
                              @change="
                                setFilterCriteria(
                                  {
                                    group: filterGroup,
                                    value: filter.id,
                                    label: filter.label,
                                  },
                                  $event
                                )
                              "
                            />{{ filter.label }}
                          </template>
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="col-lg-10" v-if="prods.total > 0" id="">
            <pagination-row
              :recordsCount="prods.total"
              :perPage="filters.perPage"
              :currPage="filters.pageNumb"
              @pageChange="changePage"
            />
            <div id="selected-filters-list-top">
              <template
                v-for="(criteria, group) in filters.criteria"
                :key="group"
              >
                <span v-for="(val, key) in criteria" :key="key">
                  {{ val }}
                  <a href="#" @click.prevent="removeFilterCriteria(group, key)"
                    >x</a
                  >
                  &nbsp;
                </span>
              </template>
            </div>
            <div
              class="product-grid-list-wrapper cart-page-table-wrapper"
              v-if="view == 'grid'"
              id="view-grid"
            >
              <div class="row">
                <product-card-quote
                  v-for="(product, index) in prods.data"
                  :key="index"
                  :max:="maxQuoteQuantity"
                  :product="product"
                  @addToWl_pc="addToWlAfterMath"
                ></product-card-quote>
              </div>
            </div>
            <div
              class="product-wrapper"
              id="view-list"
              v-else-if="view == 'list'"
            >
              <div class="card product-card border-0">
                <div class="card-header text-white bg-grey d-none d-lg-block">
                  <div class="row mx-0">
                    <div class="col-auto product-img-wrap"></div>
                    <div class="col product-name pl-0">Product Name</div>
                    <div class="col col-lg-auto quantity-quote">Qty</div>
                    <div class="col col-lg-auto lot-size">Unit Type</div>
                    <div class="col-auto action-quote"></div>
                  </div>
                </div>

                <!-- Code for new design table to div  -->

                <div
                  v-for="(product, index) in prods.data"
                  :key="index"
                  class="card-body position-relative"
                >
                  <div class="row no-gutters">
                    <router-link
                      class="p-0 w-auto m-0"
                      :to="`/${product.seo_url}`"
                    >
                      <Popper
                        v-if="product.display_ribbon"
                        :arrow="true"
                        :hover="true"
                        :content="`${product.ribbon_name}`"
                      >
                        <div class="ribbon2-flower-library">
                          <span>{{ product.ribbon_name }}</span>
                        </div>
                      </Popper>
                    </router-link>
                    <div
                      class="
                        col-auto
                        product-img-wrap
                        position-relative
                        no-overflow
                      "
                    >
                      <router-link
                        class="p-0 w-auto m-0"
                        :to="`/${product.seo_url}`"
                      >
                        <img
                          v-if="product.image"
                          :src="`${imagePath}` + product.image"
                          class="m-0 zoom"
                          :alt="product.name"
                        />
                        <img
                          v-else
                          :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          "
                          class="m-0"
                          :alt="product.name"
                        />
                      </router-link>

                      <add-to-wishlist
                        v-if="isLoggedIn"
                        :prod="product"
                        class="fr-wish"
                      ></add-to-wishlist>
                      <span></span>
                    </div>
                    <div class="col product-name-quote breakdown-popper tool-tip pl-0">
                      <div>
                        <Popper
                          v-if="
                            product.breakdown != null &&
                            product.breakdown.length > 2
                          "
                          :arrow="true"
                          :hover="true"
                        >
                          <template #content
                            ><div
                              v-html="
                                getBreakdown(
                                  product.breakdown,
                                  product.box_unit_singular
                                )
                              "
                            ></div>
                          </template>
                          <span class="star-color"
                            ><i class="fa-solid fa-star" aria-hidden="true"></i
                          ></span>
                        </Popper>

                        <router-link :to="`/${product.seo_url}`"
                          >{{ product.name }}
                        </router-link>
                        <br />
                        <span
                          ><i>{{ product.farm_name }}</i></span
                        >
                      </div>
                    </div>
                    <div class="w-100 d-block d-lg-none"></div>
                    <div class="col-auto quantity-quote text-right">
                      <div>
                        <label class="d-block d-lg-none">Qty</label>
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <input
                            type="number"
                            :min="product.increment_quantity"
                            :max="maxQuoteQuantity"
                            :step="product.increment_quantity"
                            v-model.number="product.qty"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md col-lg-auto lot-size">
                      <div>
                        <label class="d-block d-lg-none">Unit Type</label>
                        <span class="product_unit" v-if="product.unit_id">
                          {{ product.unit }}</span
                        >
                        <select
                          v-else
                          @change="setUnitType($event, product)"
                          class="
                            form-select
                            product_unit
                            d-inline-block
                            w-auto
                            bg-transparent
                          "
                        >
                          <option
                            v-for="(fllUnitType, index) in fllUnitTypes"
                            :key="index"
                            :value="fllUnitType.id"
                            :selected="fllUnitType.id == fllUnitTypes[0].id"
                          >
                            {{ fllUnitType.unit }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6 col-md-auto action-quote text-right">
                      <div>
                        <label class="d-none d-md-block d-lg-none"
                          >&nbsp;</label
                        >
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <add-to-quote
                            :quant="product.qty"
                            :prods="[product]"
                            :quote_date="filters.tmpDate"
                            :comingSoon="product.coming_soon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Code end   -->
              </div>
            </div>
            <div class="mt-10">
              <pagination-row
                :recordsCount="prods.total"
                :perPage="filters.perPage"
                :currPage="filters.pageNumb"
                @pageChange="changePage"
              />
            </div>
            <!-- <div id="pagination-list">
              <ul class="pagination justify-content-end mt-1"><li class="page-item active"><a class="page-link" href="JavaScript:Void(0);"><span>1</span></a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=2">2</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=3">3</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=4">4</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=5">5</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=6">6</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=7">7</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=8">8</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=9">9</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=2">&gt;</a></li><li class="page-item"><a class="page-link" href="/shop?path=&amp;page=17">&gt;|</a></li></ul>
          </div> -->
          </div>
          <div v-if="filters.criteria && prods.total < 1" class="col-lg-10">
            <div id="selected-filters-list-top">
              <template
                v-for="(criteria, group) in filters.criteria"
                :key="group"
              >
                <span v-for="(val, key) in criteria" :key="key">
                  {{ val }}
                  <a href="#" @click.prevent="removeFilterCriteria(group, key)"
                    >x</a
                  >
                  &nbsp;
                </span>
              </template>
            </div>
            <p class="text">
              The filters you have selected do not match any available products.
              Remove or select a different filter combination to view our
              selection of fresh blooms.
            </p>
          </div>

          <div
            id="page_content_below"
            class="mt-10 content-wrapper px-0"
            v-if="!embed && !!getMetaDetails.description_below_dynamic"
            v-html="getMetaDetails.description_below_dynamic"
          ></div>

          <recently-viewed v-if="!embed" />
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import {
  PaginationRow,
  ProductCardQuote,
  FlashAlert,
  AddToWishlist,
  DatePicker,
  AddToQuote,
  RecentlyViewed,
  PageMetadata,
  PageCanonical,
} from "@/components";
import axios from "axios";
import { misc } from "@/mixins";
import Popper from "vue3-popper";

export default {
  name: "FlowerLibrary",
  props: {
    embed: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [misc],
  components: {
    PaginationRow,
    ProductCardQuote,
    FlashAlert,
    AddToWishlist,
    Popper,
    DatePicker,
    AddToQuote,
    RecentlyViewed,
    PageMetadata,
    PageCanonical,
  },
  mounted() {
    if (!this.embed)
      localStorage.setItem("currentPage", "flower-library");
    this.fetchProductsTypes();
    this.fetchProgramDetails();
    if (!this.embed)
      this.fetchMetaDetails();
    this.getProgramSettings();

    if ("perPage" in this.$route.query || "f_perPage" in this.$route.query) {
      this.triggerWatch = false;
      this.filters.perPage = parseInt(
        this.$route.query.perPage ?? this.$route.query.f_perPage,
        10
      );
    }
    if ("sortBy" in this.$route.query || "f_sortBy" in this.$route.query) {
      this.filters.sortBy =
        this.$route.query.sortBy ?? this.$route.query.f_sortBy;
    }
    if ("pageNumb" in this.$route.query || "f_pageNumb" in this.$route.query) {
      this.filters.pageNumb = parseInt(
        this.$route.query.pageNumb ?? this.$route.query.f_pageNumb,
        10
      );
      setTimeout(() => {
        this.pageLoaded = true;
      }, 1000);
    }
    if ("criteria" in this.$route.query || "f_criteria" in this.$route.query) {
      this.filters.criteria = JSON.parse(
        this.$route.query.criteriaInt ?? this.$route.query.f_criteriaInt
      );
    }
    if ("date" in this.$route.query || "f_date" in this.$route.query) {
      this.filters.tmpDate = this.$route.query.date ?? this.appendTimeForBrowser(this.$route.query.f_date);
      this.setCriteriaDate();
    }
    if ("byMonth" in this.$route.query) {
      this.filters.byMonth = this.$route.query.byMonth;
    }
    if ("isEntire" in this.$route.query) {
      this.filters.isEntire = this.$route.query.isEntire;
    }
    if ("search" in this.$route.query || "f_search" in this.$route.query) {
      this.filters.search = decodeURI(
        this.$route.query.search
          ? this.$route.query.search
          : this.$route.query.f_search != undefined
          ? this.$route.query.f_search
          : ""
      );
      if (this.filters.search == undefined) this.filters.search = "";
      this.filters.is_search = false;
      if (this.$route.query.search != undefined) this.filters.is_search = true;
    }
    this.$nextTick(() => {
      this.triggerWatch = true;
      this.fetchFilters();
    });

    this.emitter.on("a2q", (payload) => {
      this.addToQuoteAfterMath(payload);
    });

    this.emitter.on("deletedProduct", () => {
      this.fetchProducts();
    });
  },
  unmounted() {
    this.emitter.off("a2q");
    this.emitter.off("deletedProduct");
  },
  data() {
    return {
      productTypes: [],
      filterSection: {},
      isLoading: false,
      availableDates: {},
      disabledDates: [],
      mixDate: '',
      maxDate: '',
      fllUnitTypes: JSON.parse(localStorage.getItem("fllUnitTypes")),
      pageStaticContent: {},
      datePickerKey: 0,
      filters: {
        perPage: this.getPerPage(),
        sortBy: this.getSortBy(),
        pageNumb: 1,
        criteria: {},
        tmpDate: "",
        date: "",
        byMonth: this.currentMonth(),
        //bySeason: "",
        isEntire: 0,
        search: "",
        is_search: false,
      },
      prods: {
        total: 0,
        data: [],
      },
      triggerWatch: true,
      pageLoaded: false,
      quoteQuant: {},

      fullPage: true,
      loader: "bars",
      displayProducts: true,
      metaTitle: "",
      metaDescription: "this is meta description",
      metaKeywords: "",
      view: localStorage.getItem("viewType")
        ? localStorage.getItem("viewType")
        : "list",
      maxQuoteQuantity: JSON.parse(localStorage.getItem("site_settings")).max_quote_quantity,
      programGenericInfo: {},
      program: {
        id: JSON.parse(localStorage.getItem("site_settings")).flower_library_id,
      },
    };
  },
  computed: {
    filtersPayload() {
      return {
        //bySeason: this.filters.bySeason,
        byMonth: this.filters.byMonth,
        date: this.filters.date,
        search: this.filters.search,
        is_search: this.filters.is_search,
      };
    },
    prodsPayload() {
      const criteria = {};
      for (let group in this.filters.criteria) {
        criteria[group] = Object.keys(this.filters.criteria[group]);
      }

      return {
        perPage: this.filters.perPage,
        sortBy: this.filters.sortBy,
        pageNumb: this.filters.pageNumb,
        criteria,
        criteriaInt: this.filters.criteria,
        date: this.filters.date,
        byMonth: this.filters.byMonth,
        isEntire: this.filters.isEntire,
        search: this.filters.search,
      };
    },
  },
  methods: {

    fetchProgramDetails() {
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getProgramGenericInfo",
          {
            program_id: this.program.id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.programGenericInfo = response.data.programInfo[0]
        });
    },
    getProgramSettings() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getFLProgramSettings", {
          headers: {
            "Content-type": "application/json;multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.error === false) {
            response.data.disabled_dates.forEach((disabledDate) => {
              const formatted = this.appendTimeForBrowser(disabledDate);
              this.disabledDates.push({
                start: formatted,
                end: formatted,
                date: disabledDate,
              });
            });
            this.minDate = this.appendTimeForBrowser(response.data.min_date);
            this.maxDate = this.appendTimeForBrowser(response.data.max_date);
          }
          
          ++this.datePickerKey;

          if (response.data.available_dates) {
            this.availableDates = response.data.available_dates;
          }

          if (this.availableDates.length > 0) {
            this.filters.tmpDate = this.appendTimeForBrowser(this.availableDates[0]);
          }
        });
    },
    setUnitType(event, prod) {
      prod.unit_id_dd = parseInt(event.target.value, 10);
    },

    entireChange() {
      if (this.filters.isEntire) {
        this.filters.byMonth = "";
        this.filters.tmpDate = "";
        this.setCriteriaDate();
      } else if (!this.filters.byMonth) {
        this.filters.byMonth = this.currentMonth();
      }
      this.fetchFilters();
    },
    byMonthChange() {
      if (this.filters.byMonth) {
        this.filters.isEntire = 0;
        this.filters.tmpDate = "";
        this.setCriteriaDate();
      } else if (!this.filters.isEntire && !this.filters.date) {
        this.filters.byMonth = this.currentMonth();
      }
      this.fetchFilters();
    },
    processDateChange() {
      this.setCriteriaDate();
      if (this.filters.date) {
        this.filters.byMonth = "";
        this.filters.isEntire = 0;
      }
      this.fetchFilters();
    },
    setCriteriaDate() {
      if (this.filters.tmpDate != "") {
        this.filters.tmpDate = this.appendTimeForBrowser(this.filters.tmpDate);
        this.filters.date = this.formatDateWithZeroFromStr(
          this.filters.tmpDate
        );
      } else {
        this.filters.tmpDate = "";
        this.filters.date = "";
      }
    },
    currentMonth() {
      return new Date()
        .toLocaleDateString("en-US", { month: "short" })
        .toLowerCase();
    },

    getBreakdown(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          if (box_in_unit == "stem")
            unit_for_bd = breakdown.bunches * breakdown.stemsBunch;
          else unit_for_bd = breakdown.bunches;

          // unit_for_bd = unit_for_bd + " " + box_in_unit;
          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</table>";
      }
      return str;
    },
    fetchProductsTypes() {
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getProductTypes",
          {}, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.productTypes = response.data.types;
          localStorage.setItem(
            "productTypes",
            JSON.stringify(response.data.types)
          );
        });
    },

    getPerPage() {
      let val;
      if (localStorage.getItem("perPage"))
        val = localStorage.getItem("perPage");
      if (val) {
        return val;
      }
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.default_products_per_page
        ? all_site_settings.default_products_per_page
        : 100;
      return parseInt(val, 10);
    },

    getSortBy() {
      let sortBy = localStorage.getItem("sortBy");
      if (sortBy != "Name-ASC" || sortBy != "Name-DESC") {
        return "Name-ASC";
      } else sortBy;
    },

    changePage(pageNumb) {
      this.filters.pageNumb = pageNumb;
    },

    setFilterCriteria(opt, evt = "") {
      if (!(opt.group in this.filters.criteria)) {
        this.filters.criteria[opt.group] = {};
      }

      let isChecked = true;
      if (evt === "") {
        isChecked = !(opt.value in this.filters.criteria[opt.group]);
      } else {
        isChecked = evt.target.checked;
      }

      if (isChecked) {
        this.filters.criteria[opt.group][opt.value] = opt.label;
      } else {
        this.removeFilterCriteria(opt.group, opt.value);
      }
    },
    removeFilterCriteria(group, key) {
      delete this.filters.criteria[group][key];
    },
    resetFilters() {
      this.triggerWatch = false;
      this.filters.perPage = 100;
      this.filters.sortBy = "Name-ASC";
      this.filters.pageNumb = 1;
      this.filters.criteria = {};
      this.filters.tmpDate = "";
      this.setCriteriaDate();
      this.filters.byMonth = this.currentMonth();
      this.filters.isEntire = 0;
      if (!this.embed) {
        this.filters.search = "";
      }
      this.$nextTick(() => {
        this.triggerWatch = true;
        this.fetchFilters();
      });
    },
    fetchProducts() {
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getFlowerLibraryProducts",
          {
            filters: this.prodsPayload,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.prods.data = response.data.products;
          this.prods.total = response.data.total;

          let routeName = "FlowerLibrary";
          let query = {
            ...this.prodsPayload,
          };
          query.criteria = JSON.stringify(query.criteria);
          query.criteriaInt = JSON.stringify(query.criteriaInt);
          query.search = encodeURI(query.search);
          if (this.embed) {
            routeName = "Search";

            [
              "perPage",
              "sortBy",
              "pageNumb",
              "criteria",
              "date",
              "criteriaInt",
            ].forEach((key) => {
              query[`f_${key}`] = query[key];
              delete query[key];
            });
            query = { ...this.$route.query, ...query };
          } else {
            query.f_search = query.search;
            query.search = "";
          }
          this.$router.push({
            name: routeName,
            query: { ...query },
            hash: this.$route.hash,
          });

          if (this.embed) {
            this.$emit("flowerTotal", { count: this.prods.total });
          }
          this.isLoading = false;
          this.viewProductListToGA()
        });
    },

    viewProductListToGA() {
      let products = this.prods.data
      
        const items = [];
        products.forEach((prod) => {
          items.push({
            item_id: prod.product_id,
            item_name: prod.name,
            item_list_id: this.programGenericInfo.id,
            item_list_name: this.programGenericInfo.name,
            item_category: prod.category_name,
            item_category2: prod.variety_name,
            item_variant: prod.color_name,
            item_category3: prod.grade_name,
          });
        });
      
        let productObj = {
          item_list_id: this.programGenericInfo.id,
          item_list_name: this.programGenericInfo.name,
          items: items
        };
        this.$gtag.event("view_item_list", productObj);
    },

    fetchFilters() {
      this.isLoading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getFlowerLibraryFilters`,
          {
            ...this.filtersPayload,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((data) => {
          this.filterSection = data.data.params.filters;

          const criteriaNew = {};
          for (const key in this.filters.criteria) {
            for (const key2 in this.filterSection) {
              if (key in this.filterSection[key2]) {
                criteriaNew[key] = {};
                for (const key3 in this.filters.criteria[key]) {
                  criteriaNew[key][key3] = this.filters.criteria[key][key3];
                }
                break;
              }
            }
          }

          this.filters.criteria = criteriaNew;
          this.isLoading = false;
        });
    },

    changeView(viewType) {
      this.view = viewType;
      localStorage.setItem("viewType", this.view);
    },

    addToWlAfterMath(addStatus) {
      let flashMsg = "";
      if (!addStatus) {
        flashMsg = "You must be logged in!";
      } else {
        flashMsg = "Successfully added to the WL!";
      }

      this.triggerFlashAlert(flashMsg);
    },
    addToQuoteAfterMath(response) {
      this.triggerFlashAlert(response.msg, response.level);
    },
    applySearch() {
      this.filters.pageNumb = 1;
      this.fetchProducts();
    },
  },
  watch: {
    "filters.perPage": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("perPage", this.filters.perPage);
        this.fetchProducts();
      }
    },
    "filters.sortBy": function () {
      if (this.triggerWatch) {
        this.filters.pageNumb = 1;
        localStorage.setItem("sortBy", this.filters.sortBy);
        this.fetchProducts();
      }
    },
    "filters.pageNumb": function () {
      if (this.triggerWatch) {
        this.fetchProducts();
      }
    },
    "filters.criteria": {
      handler() {
        if (this.triggerWatch) {
          if (this.pageLoaded) this.filters.pageNumb = 1;
          this.fetchProducts();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.font-weight-bold {
  font-weight: bold;
}
.input-group-append {
  right: 0px;
}
.col.product-name.pl-0 div a {
  text-decoration: none;
}
.filters-selected {
  color: #000;
  padding: 5px 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
}
.filters-selected a {
  text-decoration: none;
}

.form-select {
  background-color: #fff !important;
}
select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
  background: #ccc;
}
li.bubble_color {
  float: left;
  padding: 5px !important;
}
#grid-list-btn-container {
  text-align: right;
}
#selected-filters-list-top {
  padding: 10px 10px 10px 0px !important;
}

#selected-filters-list-top span {
  color: #000;
  padding: 10px 15px;
  margin-right: 10px;
  float: left;
  background: #f2f2f2;
  font-weight: normal;
  font-size: 12px;
}
#view-grid .product-wrapper {
  min-height: 250px !important;
  max-height: 500px;
}
.mt-10 {
  margin-top: 10px;
}

.bubble_color_wrapper {
  text-align: center;
  display: inline-block;
}
.calender_container {
  margin-left: 0px;
}

.star-color {
  color: #f37f62;
}

.tbl_breakdown,
.tbl_breakdown tr {
  border: 1px solid #fff;
}

.tbl_breakdown {
  width: 350px;
}
.entire_library_input {
  margin-left: 35px;
}
.entire_library {
  padding-left: 10px;
}
#srch-product {
  width: 180px;
}
.product_unit {
  text-transform: capitalize;
}

.filter-image.filter-color {
  position: relative;
}
@media (max-width: 991px) {
  .product-card .product-name-quote div {
    padding-top: 12px;
  }
  .product-card .product-name-quote {
    flex: 1 0 calc(100% - 106px);
    margin-bottom: 0;
  }
  .product-card .product-name-quote a {
    text-decoration: none;
  }
  .product-card .quantity-quote,
  .product-card .lot-size,
  .product-card .unit-type,
  .product-card .action-quote {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }
}
@media (max-width: 767px) {
  .product-card .quantity-quote {
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-card .unit-type {
    flex-basis: 34%;
    max-width: 34%;
  }
  .product-card .action-quote {
    flex-basis: 46%;
    max-width: 46%;
  }
}
</style>
