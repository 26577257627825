<template>
  <section class="mt-4">
    <PageMetadata v-if="foundPendingOrder" :pageInformation="pendingOrder" />
    <vue-headful
      :title="getMetaDetails.meta_title"
      :description="getMetaDetails.meta_description"
      :keywords="getMetaDetails.meta_keywords"
    />
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="header px-0">PREBOOK #: {{ pendingOrder.prebookNumber }}</h1>
        <p v-if="pendingOrder.customerName">{{ pendingOrder.customerName }} ({{ pendingOrder.customerCode }})</p>
      </div>

      <div class="row" v-if="pendingOrder.length > 0">
        <div class="col-md-6 pl-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0">
                  <span class="fw-bold">PO #:</span>
                  {{ pendingOrder.customerPoNumber }}
                </p>
                <p class="my-0">
                  <span class="fw-bold">Carrier Name:</span>
                  {{ pendingOrder.carrierName }}
                </p>
                <p class="my-0">
                  <span class="fw-bold">Program:</span>
                  {{ pendingOrder.programName }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pr-0">
          <div class="billing-wrapper mb-4">
            <div class="billing-container">
              <div class="">
                <p class="my-0"><span class="fw-bold">Order Date:</span>
                  {{ formatDateWithDash(pendingOrder.truckDate) }}
                </p>
                <p class="my-0"><span class="fw-bold">Created On:</span>
                  {{ formatDateWithDash(pendingOrder.createdOn) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="pendingOrder.details.length > 0">
        <div class="table-responsive px-0">
          <table class="table table-striped table-bordered text-center">
            <thead class="bg-grey">
              <tr>
                <th class="text-start">Product Name</th>
                <th>Total Units</th>
                <th class="text-end">Price</th>
                <th class="text-end">Amount</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in pendingOrder.details"
                :key="index"
              >
                <td class="text-start">{{ item.productDescription }}</td>
                <td>{{ item.totalUnits }}</td>
                <td class="text-end"><span v-if="item.unitPrice > 0">${{ $filters.numberFormat(item.unitPrice) }}</span><span v-else>-</span></td>
                <td class="text-end"><span v-if="item.unitPrice > 0">${{ $filters.numberFormat(item.totalPrice) }}</span><span v-else>-</span></td>
              </tr>
            </tbody>
          </table>

          <div class="pull-right">
            <h4 v-if="pendingOrder.total > 0" class="py-0 my-0 fw-bold" style="text-align-last: right; padding: 0.5rem">
              Total: ${{ $filters.numberFormat(pendingOrder.total) }}
            </h4>
          </div>
          <div class="mt-5 mb-0">
            <p>Note: The pending total may not include delivery or freight charges.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, PageMetadata } from "@/components";
import { mapMutations } from "vuex";

export default {
  name: "PendingOrderDetails",
  mixins: [misc],
  components: { FlashAlert, PageMetadata },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      fullPage: true,
      pending_order_id: this.$route.params.pending_order_id,
      truck_date: this.$route.params.truck_date,
      pendingOrder: {
        details: {}
      },
      foundPendingOrder: false,
    };
  },
  created() {
    this.pendingOrderDetails();
  },
  mounted() {
    localStorage.setItem("currentPage", "order-details");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
  },

  methods: {
    ...mapMutations(["updateDynamicBreadcrumb"]),
    scrollToMessage() {
      // Without this setTimeout, an "undefined" error is thrown.
      setTimeout(() => {
        let el = this.$el.getElementsByClassName("alert-success")[0];
        el.scrollIntoView();
      }, 0);
    },
    showLoader() {
      this.isLoading = true;
    },
    pendingOrderDetails() {
      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getPendingOrderDetails",
            {
              pending_order_id: this.pending_order_id,
              date: this.truck_date,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (!response.data.error) {
              this.pendingOrder = response.data.pending_order_details;
              this.pendingOrder.name = 'Prebook # ' + this.pendingOrder.prebookNumber;
              this.pendingOrder.no_index = 0;
              this.foundPendingOrder = true;
              const crumb = [
                {
                  routeName: "Home",
                  text: '<i class="fa-solid fa-house-chimney"></i>',
                },
                {
                  routeName: "Account",
                  text: 'Account',
                },
              ];
              let pendingOrderNumber = response.data.pending_order_details.prebookNumber
              crumb.push({
                route: this.$route.fullPath,
                text: 'Pending Order ' + pendingOrderNumber,
              });
              this.updateDynamicBreadcrumb(crumb);
            } else {
              this.showFlashAlert(response.data.message, "error");
            }
            this.isLoading = false;
          });
      } catch {
        this.isLoading = false;
      }
    },
  },
};
</script>