<template>
  <div class="col-lg-3 mb-4 col-6 product-grid no-overflow">
    <div class="product-wrapper text-center">
      <div class="product-img px-4 py-3 position-relative">
        <add-to-wishlist v-if="isLoggedIn" :prod="product" class="fr-wish"></add-to-wishlist>
        <template v-if="deliveryDate != ''">
          <router-link :to="`/${product.seo_url}?date=${deliveryDate}`">
            <div class="ribbon" v-if="product.highlight_id">
              <span
                :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                product.highlight_name }}</span>
            </div>
            <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
            <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.name" />
          </router-link>
        </template>
        <template v-else>
          <router-link :to="`/${product.seo_url}`">
            <div class="ribbon" v-if="product.highlight_id">
              <span
                :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                product.highlight_name }}</span>
            </div>
            <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
            <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.name" />
          </router-link>
        </template>
      </div>
      <div class="px-3 pb-3">
        <div class="product-details">
          <div class="product-name mb-1">
            <Popper class="d-flex mr-2 breakdown-popper d-inline-flex"
              v-if="product.breakdown != null && product.breakdown.length > 2" :arrow="true" :hover="true">
              <template #content>
                <div class="breakdown-popper" v-if="product.programtype == 'lal' || product.programtype == 'prb' || product.programtype == 'wsp'" 
                  v-html="getBreakdownForPulls(product.breakdown, product.box_unit_singular)"></div>
                <div v-else v-html="getBreakdown(product.breakdown, product.box_unit_singular)"></div>
              </template>
              <span class="star-color"><i class="fa-solid fa-star text-warning" aria-hidden="true"></i></span>
            </Popper>
            <span class="d-inline-block highlight-description" v-if="product.highlight_description">
              <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                <i class="fa-solid fa-circle-info"></i>
              </Popper>
            </span>
            <template v-if="deliveryDate != ''">
              <router-link :to="`/${product.seo_url}?date=${deliveryDate}`">{{ product.name }}</router-link><br />
            </template>
            <template v-else>
              <router-link :to="`/${product.seo_url}`">{{ product.name }}</router-link><br />
            </template>
            <Popper v-if="product.country_id > 0" :arrow="true" :hover="true" :content="product.country_name">
              <div class="text-center is_us_container">
                <span class="us_img_icon d-inline-block">
                  <img :src="`${imagePath}` + product.country_flag" /></span>
              </div>
            </Popper>
            <Popper v-if="product.state_id > 0" :arrow="true" :hover="true" :content="product.state_name">
              <div class="text-center is_us_container">
                <span class="us_img_icon d-inline-block">
                  <img :src="`${imagePath}` + product.state_flag" /></span>
              </div>
            </Popper>
          </div>
          <template v-if="isLoggedIn">
            <p>
              <small><i>{{ product.farm_name }}</i></small>
            </p>
            <p>
              <small>
                <span v-if="product.programtype == 'mdb' || product.programtype == 'lal' || product.programtype == 'fdb'">{{ product.qty }}</span>
                {{
                product.qty > 1
                ? product.box_name_plural
                : product.box_name_singular
                }}

                <span v-if="product.box_name_singular != product.box_unit_singular">({{ product.unit_count }}
                  {{
                  product.unit_count > 1
                  ? product.box_unit_plural
                  : product.box_unit_singular
                  }})</span>
              </small>
            </p>
            <p><small>&nbsp;</small></p>
            <p>
              <b>${{ $filters.numberFormat(product.price_per_stem) }} / {{ product.box_unit_singular }}</b>
              <Popper v-if="product.special" :arrow="true" :hover="true" :content="`${product.special}% Off`">
                <div class="ms-2 badge bg-peach">
                  <span>{{ product.special }}% Off</span>
                </div>
              </Popper>
            </p>
            <p>
              <small v-if="product.box_name_singular != product.box_unit_singular">
                ${{ $filters.numberFormat(product.price_per_unit) }} /
                {{ product.box_name_singular }}
              </small>
              <small v-else>&nbsp;</small>
            </p>
          </template>
        </div>
        <div class="d-flex align-items-center justify-content-center my-2">
          <a v-if="!isLoggedIn" :href="'/login'" class="btn btn-theme ml-1">Login</a>
          <template v-else>
            <add-to-cart :max="product.qty" :min="product.min_qty" :step="product.inc_quantity" :prod="product"
              :deliveryDate="deliveryDate" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { misc } from "@/mixins";
import AddToCart from "./AddToCart.vue";
import AddToWishlist from "./AddToWishlist.vue";
import Popper from "vue3-popper";
export default {
  name: "ProductCard",
  props: {
    max: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      required: true,
    },
    deliveryDate: {
      required: true,
    },
  },
  components: {
    AddToCart,
    AddToWishlist,
    Popper,
  },
  mixins: [misc],
  data() {
    return {
      us_message: "US Product",
    };
  },
  mounted() {
    let site_settings = JSON.parse(localStorage.getItem("site_settings"));
    this.us_message = site_settings.us_flag_message;
  },
  methods: {
    getBreakdownForPulls(breakdownJson, box_in_unit) {
      let breakdowns = JSON.parse(breakdownJson);
      let str = "";
      let unit_for_bd = "";
      if (breakdowns.length > 0) {
        str =
          '<table class="tbl_breakdown"><thead><tr><td colspan=3 align="center">Breakdown – Subject to Change</td></tr><thead><tbody><tr class="popper-content-header"><td align="left">Product</td><td align="right">Units</td></tr>';
        breakdowns.forEach((breakdown) => {
          unit_for_bd = breakdown.bunches;
          if (breakdown.stemsBunch == "bunch") {
            if (breakdown.bunches > 1) box_in_unit = "bunches";
            else box_in_unit = "bunch";
          } else if (breakdown.stemsBunch == "stem") {
            if (breakdown.bunches > 1) box_in_unit = "stems";
            else box_in_unit = "stem";
          }

          str =
            str +
            '<tr><td align="left">' +
            breakdown.productDescription +
            "</td>" +
            '<td align="right">' +
            unit_for_bd +
            " " +
            box_in_unit +
            "</td></tr>";
        });

        str = str + "</tbody></table>";
      }
      return str;
    },
  },
};
</script>

<style scoped>
.card {
  border: 1px solid #000;
  padding: 5px;
  margin-right: 10px;
}
.no-overflow {
  overflow: initial;
}
.no-overflow .product-wrapper,
.no-overflow .product-wrapper .product-img {
  overflow: initial !important;
}
.us_img_icon {
  width: 40px;
}
/* .us_img_icon img{width:40px; height: 20px;} */
.is_us_container {
  width: 40px;
  margin: 0px auto;
}
.star-color {
  color: #f37f62;
}
.product-name a {
  text-decoration: none;
}

.product-grid :deep(.cutoff_label) { margin: 0 auto !important;}
@media screen and (max-width: 480px) {
  .product-name a {
    text-decoration: none;
    font-size: 12px;
  }
  .product-name {
    height: 70px !important;
  }

  
}
</style>
<style>
.tbl_breakdown {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}

.text-center .tbl_breakdown th,
.text-center .tbl_breakdown th {
  text-align-last: initial;
  padding-left: 5px;
}

.text-left {
  text-align-last: initial !important;
  padding-left: 5px;
}
.text-left td {
  padding-left: 5px !important;
}
.breakdown-popper .popper #arrow {
  display: none;
}
.breakdown-popper .popper {
  padding: 0px;
  background: #5c5c5c !important;
  border-radius: 30px;
  color: #fff;
}
.breakdown-popper tr {
  border: 0 !important;
}
.breakdown-popper td,
.breakdown-popper th {
  border: 0 !important;
  padding: 2px !important;
  vertical-align: top;
  color: inherit !important;
}
.breakdown-popper tr > td:first-child,
.breakdown-popper tr > th:first-child {
  padding-left: 30px !important;
}
.breakdown-popper tr > td:last-child,
.breakdown-popper tr > th:last-child {
  padding-right: 30px !important;
  text-align: right;
}
.breakdown-popper thead td {
  padding: 10px !important;
  border-bottom: 1px solid #fff !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.breakdown-popper tbody tr td:first-child {
  padding-right: 25px !important;
}
.breakdown-popper tbody tr:first-child td {
  padding-top: 15px !important;
}
.breakdown-popper tbody tr:last-child td {
  padding-bottom: 15px !important;
}
.breakdown-popper tbody tr.popper-content-header {
  font-size: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
} 
</style>