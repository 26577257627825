<template>
  <section class="my-5">
    <PageMetadata v-if="!embed" />
    <PageCanonical v-if="total_post > 0" :canonical="{ totalRecords: total_post }" />
    <flash-alert v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
    />
    <template v-if="!embed">
      <div class="row" v-if="getMetaDetails.image != null && getMetaDetails.image_above_h1 == 1">
        <div class="col-md-12">
          <img :src="`${imagePath}` + `${getMetaDetails.image}`" class="w-100" />
        </div>
      </div>
      <h1 class="pb-0 mb-4 px-0" :class="getMetaDetails.h1_classes">{{ getMetaDetails.page_h1 }}</h1>
      <div class="row" v-if="getMetaDetails.image != null && getMetaDetails.image_above_h1 == 0">
        <div class="col-md-12">
          <img :src="`${imagePath}` + `${getMetaDetails.image}`" class="w-100" />
        </div>
      </div>
      <div id="page_content" class="mt-10 content-wrapper" v-if="getMetaDetails.image != null && getMetaDetails.image_above_h1 == 1" v-html="getMetaDetails.description"></div>
      <div class="content_below_heading" v-else-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
    </template>
    <div v-if="this.filters.tag" class="mb-5">
      <p class="content-p mb-0 d-inline">Tag: </p>
      <h5 class="my-0 d-inline">{{ this.filters.tag }}</h5>
    </div>
    <div class="row align-items-center no-gutters mx-0">
      <div class="col-md-6 col-12 ps-0 mb-3 mb-md-0">
        <div class="input-group" v-if="!embed">
          <input
            type="text"
            class="form-control width-0"
            aria-label="Search"
            aria-describedby="button-addon2"
            v-model="filters.search"
            @keyup.enter="applySearch"
          />
          <button class="btn btn-theme" type="button" id="button-addon2" @click="applySearch">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
          <div class="text-md-start text-center ms-2">
            <input type="button" id="reset-btn" class="btn btn-theme h-100" value="Reset" @click="resetFilters" />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <pagination-row
          :recordsCount="this.total_post"
          :perPage="filters.perPage"
          :currPage="filters.pageNumb"
          @pageChange="changePage"
        />
      </div>
    </div>
    <div class="blog-grid row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4 my-4">
      <div class="col" v-for="(post, index) in posts" :key="index">
        <article class="card h-100 shadow-lg border-0">
          <a class="text-reset" :href="post.url"><img :src="post.featured_image" alt="" class="card-img-top"></a>
          <div class="card-body">
            <h3 class="mb-3">
              <a class="card-title text-reset" :href="post.url">{{ post.name }}</a>
            </h3>
            <div class="card-text regular-p mb-0" v-html="shorten(post.summary, 100)"></div>
          </div>
          <div class="card-footer text-end bg-transparent border-top-0 mb-2">
            <a :href="post.url" class="h6">Read More <i class="fa-solid fa-angle-right" style="color: #f48062"></i></a>
          </div>
        </article>
      </div>
      <h2 class="" v-if="posts.length == 0 ">{{no_result_msg }}</h2>
    </div>      
    <div class="row no-gutters mx-0">
      <pagination-row
        :recordsCount="this.total_post"
        :perPage="filters.perPage"
        :currPage="filters.pageNumb"
        @pageChange="changePage"
      />
    </div>
    <div v-if="!embed" id="page_content_below" class="mt-10 content-wrapper" v-html="getMetaDetails.description_below_dynamic"></div>
    <div v-if="!embed" class="mt-5 py-3 mx-auto text-center" id="hubspot-form" style="max-width: 555px;">
      <h2>SUBSCRIBE TO OUR BLOG</h2>
    </div>
  </section>
</template>

<script>
import { FlashAlert, PaginationRow, PageMetadata, PageCanonical } from "@/components";
import { mapGetters } from "vuex";
import { misc } from "@/mixins";
import axios from "axios";

export default {
  name: "BlogPage",
  props: {
    embed: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [misc],
  components: {
    PaginationRow,
    FlashAlert, 
    PageMetadata,
    PageCanonical,
  },
  mounted() {
    if (!this.embed) {
      localStorage.setItem("currentPage", "blog");
      this.fetchMetaDetails();
    }
    if ("perPage" in this.$route.query || "blog_perPage" in this.$route.query) {
      this.triggerWatch = false;
      this.filters.perPage = this.getPerPage()
    }
    if ("pageNumb" in this.$route.query || "blog_pageNumb" in this.$route.query) {
      this.filters.pageNumb = parseInt(
        this.$route.query.pageNumb ?? this.$route.query.blog_pageNumb,9
      );
      setTimeout(() => {
        this.pageLoaded = true;
      }, 1000);
    }
    if ("search" in this.$route.query || "blog_search" in this.$route.query) {
      this.filters.search = decodeURI(
        this.$route.query.search ?? this.$route.query.blog_search
      );
    }

    if ("tag" in this.$route.query ) {
      this.filters.tag = decodeURI(
        this.$route.query.tag ?? this.$route.query.tag
      );
    }
    
    this.$nextTick(() => {
      this.triggerWatch = true;
    });

    if (this.showBlogPost) {
      this.fetchBlogPosts()
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      showBlogPost: this.canShowBlogPost(),
      no_result_msg: this.getNoResultMsg(),
      posts:[],
      total_post: 0,
      filters: {
        perPage: this.getPerPage(),//localStorage.getItem("blog_default_limit") ? localStorage.getItem("blog_default_limit"): 9, //this.getPerPage() ,
        pageNumb: 1,
        search: "",
        tag: "",
      },
      view: localStorage.getItem("viewType")
        ? localStorage.getItem("viewType")
        : "list",
      triggerWatch: true,
      pageLoaded: false,
      portalId: '',
      subscribeFormId: '',
    };
  },
  computed: {
    ...mapGetters(["isSalesRep", "isESales"]),

    apiBody() {
      return {
        perPage: this.filters.perPage,
        pageNumb: this.filters.pageNumb,
        search: this.filters.search,
        tag: this.filters.tag,
      };
    },
  },
  methods: {
    applySearch() {
      this.filters.pageNumb = 1;
      this.fetchBlogPosts();
    },
    resetFilters() {
      this.filters.pageNumb = 1;
      this.filters.search = '';
      this.filters.tag = '';
      this.fetchBlogPosts();
    },
    getPerPage() {
      let val;
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.blog_default_limit
        ? all_site_settings.blog_default_limit
        : 9;
      return parseInt(val)
    },

    canShowBlogPost() {
      let val;
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.blog_display_search
        ? all_site_settings.blog_display_search
        : 0;
      return val == 1 ? true: false
    },

    getNoResultMsg() {
      let val;
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.blog_message_no_results
        ? all_site_settings.blog_message_no_results
        : 'No results found';
      return val
    },

    truncateWithHTML(string, length) {
      // string = "<span class='className'>My long string that</span> I want shorter<span> but just a little bit</span>"

      const noHTML = string.replace(/<[^>]*>/g, '');
      let max = 100
      // if the string does not need to be truncated
      if (noHTML.length <= max){
          return string;
      }

      // if the string does not contains tags
      if (noHTML.length === string.length){
          // add <span title=""> to allow complete string to appear on hover
          return `<span title="${string}">${string.substring(0, max).trim()}…</span>`;
      }

      const substrings =  string.split(/(<[^>]*>)/g).filter(Boolean);
      // substrings = ["<span class='className'>","My long string that","</span>"," I want shorter","<span>"," but just a little bit","</span>"]

      let count = 0;
      let truncated = [];
      for (let i = 0; i < substrings.length; i++) {
          let substr = substrings[i];
          // if the substring isn't an HTML tag
          if (! substr.startsWith("<")){
              if (count > length){
                  continue;
              } else if (substr.length > (length-count-1)){
                  truncated.push(substr.substring(0, (length-count) - 1) + '…');
              } else {
                  truncated.push(substr);
              }
              count += substr.length;
          } else {
              truncated.push(substr);
          }
      }

      return `<span title="${noHTML}">${truncated.join("")}</span>`;
    },

    shorten: function(string, len) {
      string = string.replace(/<\/?[^>]+(>|$)/g, "");
      return string.substring(0, len + string.substring(len - 1).indexOf(' ')) + '....';
    },

    
    changePage(pageNumb) {
      this.filters.pageNumb = pageNumb;
    },
    fetchBlogPosts() {
      if (!this.showBlogPost) {
        return false
      }
      this.isLoading = true;
        axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/getBlogPosts",
          {
            // filters: this.apiBody,
            ...this.apiBody
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.posts = response.data.posts;
          this.total_post = response.data.total;

          let query = {
            ...this.apiBody,
          };
          let routeName = "Blog";
          query.search = encodeURI(query.search);
          if (this.embed) {
            routeName = "Search";

            [
              "perPage",
              "pageNumb",
            ].forEach((key) => {
              query[`blog_${key}`] = query[key];
              delete query[key];
            });
            query = { ...this.$route.query, ...query };
          } else {
            query.blog_search = query.search;
            query.search = "";
          }
          this.$router.push({
            name: routeName,
            query: { ...query },
            hash: this.$route.hash,
          });

          if (this.embed) {
            this.$emit("BlogTotal", { count: this.total_post });
          }

          this.isLoading = false;
        });

        let site_settings = JSON.parse(localStorage.getItem('site_settings'));
        this.portalId = site_settings.hub_spot_portal_id;
        this.subscribeFormId = site_settings.hub_spot_blog_form_id;

        if (this.portalId && this.subscribeFormId) {
            const script = document.createElement("script");
            script.src = "//js.hsforms.net/forms/v2.js";
            document.body.appendChild(script);
            script.addEventListener("load", () => {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  portalId: this.portalId,
                  formId: this.subscribeFormId,
                  target: "#hubspot-form",
                });
              }
            });
          }
    },
    
  },
  watch: {
    "filters.pageNumb": function () {
      if (this.triggerWatch) {
        this.fetchBlogPosts();
      }
    },
    
  },
  
};
</script>
<style scoped>
  .blog-grid .card-img-top{
    max-height:210px;
    object-fit:cover;
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .col.product-name.pl-0 div a {
    text-decoration: none;
  }
  h3 {
    letter-spacing: 1px !important; 
    font-weight: 500px; 
    text-transform: initial !important;
  }
</style>
