<template>
  <section>
    <PageMetadata />
    <flash-alert class="my-2" v-if="!embed" v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div class="row" v-if="pageDetails.image != null && pageDetails.image_above_h1 == 1">
      <div class="col-md-12"><img :src="`${imagePath}` + `${pageDetails.image}`" class="w-100" /></div>
    </div>
    <h1 class="header text-center pt-80" :class="pageDetails.h1_classes">{{ pageDetails.page_h1 }}</h1>
    <div class="row" v-if="pageDetails.image != null && pageDetails.image_above_h1 == 0">
      <div class="col-md-12">
        <img :src="`${imagePath}` + `${pageDetails.image}`" class="w-100" />
      </div>
    </div>
    <div id="page_content" class="mt-10 content-wrapper" v-html="pageDetails.description"></div>
    <div id="page_content" class="mt-10 content-wrapper" v-html="pageDetails.description_below_dynamic"></div>
  </section>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import { mapGetters } from "vuex";
import { FlashAlert, PageMetadata } from "@/components";
export default {
  name: "OrderThankYou",
  mixins: [misc],
  components: { FlashAlert, PageMetadata },
  data() {
    return {
      pageDetails: {},
      slug: 'order-thank-you',
    };
  },
  computed: {
    ...mapGetters(["canEnrollLoyalty", "canChangeLoyaltyStatus", "canViewLoyalty"]),
  },
  mounted() {
    localStorage.setItem("currentPage", this.slug);
    this.fetchDetails();
    this.fetchMetaDetails();
  },
  methods: {
    fetchDetails() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getPageContent`,
          {
            slug: this.slug,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.pageDetails = response.data.content;

          if (
            "hubspot_portal_id" in this.pageDetails &&
            "hubspot_form_id" in this.pageDetails &&
            this.pageDetails.hubspot_portal_id &&
            this.pageDetails.hubspot_form_id
          ) {
            const script = document.createElement("script");
            script.src = "//js.hsforms.net/forms/v2.js";
            document.body.appendChild(script);
            script.addEventListener("load", () => {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  portalId: this.pageDetails.hubspot_portal_id,
                  formId: this.pageDetails.hubspot_form_id,
                  target: "#hubspot-form",
                });
              }
            });
          }
          if ("jot_form_id" in this.pageDetails && this.pageDetails.jot_form_id) {
            let scriptJotForm = document.createElement("script");
            scriptJotForm.src = "//js.jotform.com/JotFormAnywhere.js";
            document.body.appendChild(scriptJotForm);
            scriptJotForm.addEventListener("load", () => {
              window.JotformAnywhere.insertForm({
                formID: this.pageDetails.jot_form_id,
                formEmbedUrl: "https://form.jotform.com/jsform/" + this.pageDetails.jot_form_id,
                insertTo: "#jot-form",
                putEditButton: false,
                putDeleteButton: false
              });
            });
          }

          this.loyaltyMessages();
          this.scrollToElement();
        }).catch((err) => {
            console.log('An error occurred while retriving the customer information. ', err);
        });
    },

    fetchLoyaltyRewardMessage() {
      if (this.canChangeLoyaltyStatus) {
        this.isLoading = true;
        axios
          .get(process.env.VUE_APP_API_BASE + "auth/orderLoyaltyThankYou", 
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (!response.data.error) {
              let element = document.getElementById('redeem-petals');
              if (element) {
                element.classList.remove('d-none');
                let message = document.getElementById('redeem-petals-message');
                if (message) {
                  message.innerHTML = response.data.message;
                }
              }
            }
            this.isLoading = false;
          }).catch((err) => {
            console.log('An error occurred while retriving the customer information. ', err);
            this.isLoading = false;
          });
      }
    },

    loyaltyMessages() {
      if (this.canEnrollLoyalty) {
          setTimeout(() => {
            let element = document.getElementById('start-saving');
            if (element) {
              element.classList.remove('d-none');
            }
          }, 500);
      } else {
        if (this.canViewLoyalty) {
          this.fetchLoyaltyRewardMessage();
        }
      }
    },

    scrollToElement() {
      if (this.$route.hash) {
        setTimeout(() => {
          const elem = document.querySelector(this.$route.hash);
          if (elem) {
            document.getElementById(elem.id).scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }
        }, 50);
      }
    },
  },
};
</script>
<style>
#page_content {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 30px;
  color: #231f20;
}

.mt-10 {
  margin-top: 10px;
}

#buying-options,
#destination-events,
#additional-tools,
#mayesh-branch,
#mayesh-market,
.scrollElement {
  scroll-margin-top: 80px;
}
@media screen and (max-width: 480px) {
  .content-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>