<template>
  <section class="mt-4">
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="content" class="col-sm-12">
      <div class="align-items-center justify-content-between mb-4">
        <h1 class="px-0">{{ getMetaDetails.page_h1 }}</h1>
        <div
          class="content_below_heading"
          v-if="!!getMetaDetails.description"
          v-html="getMetaDetails.description"
        ></div>
      </div>
      <div class="row">
        <div class="col-sm-9">
          <div class="well">
            <form @submit.prevent="doLogin">
              <div class="form-group">
                <label class="control-label" for="input-email">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  v-model="form.password"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label class="control-label" for="input-password"
                  >Confirm Password</label
                >
                <input
                  type="password"
                  placeholder="Confirm Password"
                  v-model="form.confirm_password"
                  class="form-control"
                />
              </div>
              <input
                type="button"
                @click="changePassword"
                value="Change Password"
                class="btn btn-primary"
              />
            </form>
          </div>
        </div>
        <aside id="column-right" class="col-sm-3 hidden-xs">
          <div class="list-group">
            <a href="/login" class="list-group-item">Login</a>
            <a href="/register" class="list-group-item">Register</a>
          </div>
        </aside>
      </div>
    </div>
    <div
      id="page_content_below"
      class="mt-10 content-wrapper"
      v-if="!!getMetaDetails.description_below_dynamic"
      v-html="getMetaDetails.description_below_dynamic"
    ></div>
  </section>
</template>

<script>
import { FlashAlert, PageMetadata } from "@/components";
import axios from "axios";
import { misc } from "@/mixins";

export default {
  name: "ChangePassword",
  mixins: [misc],
  components: {
      FlashAlert,
      PageMetadata
  },
  data() {
    return {
      form: {
        password: "",
        confirm_password: "",
      },
    };
  },
  mounted() {
    localStorage.setItem("currentPage", "reset-password");
    this.fetchMetaDetails();
  },
  methods: {
    changePassword() {
      let token = this.$route.params.token;
      axios
        .post(
          process.env.VUE_APP_API_BASE + "auth/reset-password",
          {
            token: token,
            password: this.form.password,
            confirm_password: this.form.confirm_password,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.error === true) {
            this.showFlashAlert(response.data.message, "error");
          } else {
            this.showFlashAlert(response.data.message, "success");
            this.form.password = "";
            this.form.confirm_password = "";
          }
        });
    },
  },
};
</script>