<template>
  <section>
    <PageMetadata />
    <div class="row" v-if="pageDetails.image != null && pageDetails.image_above_h1 == 1">
      <div class="col-md-12"><img :src="`${imagePath}` + `${pageDetails.image}`" class="w-100" /></div>
    </div>
    <h1 class="header text-center pt-80" :class="pageDetails.h1_classes">{{ pageDetails.page_h1 }}</h1>
    <div class="row" v-if="pageDetails.image != null && pageDetails.image_above_h1 == 0">
      <div class="col-md-12"><img :src="`${imagePath}` + `${pageDetails.image}`" class="w-100" /></div>
    </div>
    <div id="page_content" class="mt-10 content-wrapper" v-html="pageDetails.description"></div>
    <div v-if="locations.length > 0" class="row locations">
      <div v-for="(location, index) in locations" :key="index" class="col-sm-12 col-md-6 col-lg-3 tall location-section">
        <p class="text-center location-state">{{ location.state_name }}</p>
        <div class="location-detail">
          <div class="location-manager">
            <p class="text-center location-city"><a :href="`/${location.seo_url}`">{{ location.name }}</a></p>
            <p class="text-center location-info">{{ location.address }}</p>
            <p class="text-center location-info">{{ location.city }}, {{ location.state_code }} {{ location.zip_code }}</p>
            <p class="text-center location-info">{{ location.phone }}</p>
            <p class="text-center location-info">Manager: {{ location.manager_name }}</p>
          </div>
          <p class="text-center location-info"><a :href="`/${location.seo_url}`">View Hours</a></p>
        </div>
      </div>
    </div>
    <div id="page_content_below" class="mt-10 content-wrapper" v-html="pageDetails.description_below_dynamic"></div>
  </section>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import { mapMutations } from "vuex";
import { PageMetadata } from "@/components";

export default {
  name: "LocationsPage",
  mixins: [misc],
  components: { PageMetadata },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      route: window.location.hash,
      pageDetails: {},
      locations: {},
    };
  },
  mounted() {
    this.fetchDetails();
    localStorage.setItem("currentPage", this.slug);
    this.fetchMetaDetails();
    this.fetchLocations();
  },
  methods: {
    ...mapMutations(["setBreadcrumbDetails"]),
    fetchDetails() {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/getPageContent`,
          {
            slug: this.slug,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.pageDetails = response.data.content;
          this.setBreadcrumbDetails(this.pageDetails);

          if (
            "hubspot_portal_id" in this.pageDetails &&
            "hubspot_form_id" in this.pageDetails &&
            this.pageDetails.hubspot_portal_id &&
            this.pageDetails.hubspot_form_id
          ) {
            const script = document.createElement("script");
            script.src = "//js.hsforms.net/forms/v2.js";
            document.body.appendChild(script);
            script.addEventListener("load", () => {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  portalId: this.pageDetails.hubspot_portal_id,
                  formId: this.pageDetails.hubspot_form_id,
                  target: "#hubspot-form",
                });
              }
            });
          }
          if ("jot_form_id" in this.pageDetails && this.pageDetails.jot_form_id) {
            let scriptJotForm = document.createElement("script");
            scriptJotForm.src = "//js.jotform.com/JotFormAnywhere.js";
            document.body.appendChild(scriptJotForm);
            scriptJotForm.addEventListener("load", () => {
              window.JotformAnywhere.insertForm({
                formID: this.pageDetails.jot_form_id,
                formEmbedUrl: "https://form.jotform.com/jsform/" + this.pageDetails.jot_form_id,
                insertTo: "#jot-form",
                putEditButton: false,
                putDeleteButton: false
              });
            });
          }
          this.scrollToElement();
        });
    },

    fetchLocations(){
      axios
        .get(
          process.env.VUE_APP_API_BASE + "auth/getLocations",
          {},
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.locations = response.data.locations;
        }); 
    },

    scrollToElement() {
      if (this.$route.hash) {
        setTimeout(() => {
          const elem = document.querySelector(this.$route.hash);
          if (elem) {
            // console.log('client bound', elem.getBoundingClientRect().top, '===', document.getElementById("header-top-menu-bar").offsetHeight)
            // window.scrollTo(
            //   0,
            //   elem.getBoundingClientRect().top -
            //     document.getElementById("header-top-menu-bar").offsetHeight - 70
            // );
            document.getElementById(elem.id).scrollIntoView({
              block: "start",
              behavior: "smooth",
              inline: "start",
            });
          }
        }, 50);
      }
    },
  },
  watch: {
    slug() {
      this.fetchDetails();
    },
    $route() {
      // react to route changes...
      this.scrollToElement();
    },
  },
};
</script>
<style>
#page_content, #page_content_below {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 30px;
  color: #231f20;
}

.mt-10 {
  margin-top: 10px;
}

#buying-options,
#destination-events,
#additional-tools,
#mayesh-branch,
#mayesh-market,
.scrollElement {
  scroll-margin-top: 80px;
}
@media screen and (max-width: 480px) {
  .content-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
